import PropTypes from "prop-types";
import React,{useState} from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/logo-dark.png";
import logo from "assets/images/logo.svg";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

const Login = props => {

  function showToast(type) {
    console.log("type123",type)
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Giriş İşleminiz Gerçekleşmiştir!"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  //meta title
  document.title = "Giriş | MEGA - Admin Paneli";

  const [getIsActive, setIsActive] = useState(false);

  const [getMail, setMail] = useState(false);
  const [getPassword, setPassword] = useState(false);

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: getMail != null ? getMail : '',
      password: getPassword != null ? getPassword : '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Lütfen Mail Adresini Giriniz"),
      password: Yup.string().required("Lütfen Şifrenizi Giriniz"),
    }),
    onSubmit: (values) => {

      if(getIsActive == true){

      localStorage.setItem("rememberMail",values.email);
      localStorage.setItem("rememberPassword",values.password);
      localStorage.setItem("rememberCheck", getIsActive);

      }
      
      dispatch(loginUser(values, props.router.navigate));
      showToast(true)

    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  React.useEffect(() => {

    //localStorage.clear()
    let rememberMe = localStorage.getItem("rememberCheck");

    if(rememberMe =="true"){
      let mailAdress = localStorage.getItem("rememberMail");
      let password1 = localStorage.getItem("rememberPassword");
    
      setIsActive(true)
      setMail(mailAdress)
      setPassword(password1)
      
    }else{

      setIsActive(false)

    }

  }, []);



    const toggleChange = () => {
      setIsActive((state) => (!state.isChecked));
    }
   
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Hoşgeldiniz !</h5>
                        <p>MEGA Admin Paneline Hoşgeldiniz.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                        <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Mail Adresi</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Mail Adresini Giriniz"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Şifre</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Şifreyi Giriniz"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                             defaultChecked={getIsActive}
                             value={getIsActive}
                             onChange={toggleChange}
                             checked={getIsActive}

                            />
                           <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Beni Hatırla
                        </label>
                          </div>



                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Giriş Yap
                        </button>
                      </div>


                    
                    </Form>
                  </div>
                </CardBody>
              </Card>
             
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
