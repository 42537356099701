import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Date, Status,NameTC } from "./contactlistCol";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import "./index.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import DateModal from "components/Common/DateModal";

import 'react-dropzone-uploader/dist/styles.css'

import {
  getAllDynamicForm as onGetAllDynamicForm,
  addPopup as onAddPopup,
  updateFormSuccessUser as onUpdateFormSuccessUser,
  deleteDynamicFormSuccessUser as onDeleteDynamicFormSuccessUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

import {ExportToExcel} from '../../ExportToExcel'
import {ExportToExcel2} from '../../ExportToExcel2'

import jsPDF from "jspdf";
import "jspdf-autotable";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

const ContactsList = props => {

  //meta title
  document.title = "Form | MEGA ADMIN PANELI";


  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setContactEdit] = useState();
  const [getIsActive, setIsActive] = useState(false);
  const [getFileName, setFileName] = useState(null);

  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
   

    },
    validationSchema: Yup.object({
    

    }),
    onSubmit: values => {
      if (isEdit) {
        const updatePopup = {
          ID: contact.ID,
          EditForm: getIsActive
        };
        // update user
        dispatch(onUpdateFormSuccessUser(updatePopup));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Popup_Name: values["name"],
          Popup_IsActive:getIsActive,
          Popup_PhotoLink:getFileName,
          Popup_Weblink:values["link"],
        };
        // save new user
        dispatch(onAddPopup(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [getItemToggle, setItemToggle] = useState(null);

  const toggleViewModal1 = () => {    
    setModal1(!modal1)};

  const toggleViewModal = (item) => {
    setItemToggle(item)
    console.log("sndflkdnsfk",item)
    
    setModal1(!modal1)};

  const { users } = useSelector(state => ({
    users: state.contacts.allDynamicFormUser,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modal1, setModal1] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },

      {
        Header: "Adı Soyadı",
        accessor: "Users.NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "TC",
        accessor: "Users.Users_TCNumber",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
    
      {
        id: "phone",
        Header: "Telefon",
        accessor: "Users.GsmNumber",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },

      {
        id: "iban",
        Header: "Iban",
        accessor: "DynamicForm_Success_Value",
        filterable: true,
        Cell: cellProps => {
          return <NameTC {...cellProps} />;
        },
      },
    
      {
        id: "Tarih",
        Header: "Tarih",
        accessor: "Created_DateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },
      
  
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetAllDynamicForm());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;

    setContact(user);
    setContactEdit(user)
    setIsEdit(true);

    setIsActive(user.EditForm)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteDynamicFormSuccessUser(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };


  const [getExcelModal, setExcelModal] = useState(false);

  const [getDateClick, setDateClick] = useState(null);

  const onClickExcel = (order) => {
    setDateClick(order);
    setExcelModal(true);
  }




  const data2 =  users.map(elt=> [
    elt.Users.NameSurname,
    elt.Users.Users_TCNumber,
    elt.Users.GsmNumber,
     "TR" + elt.DynamicForm_Success_Value
    ]);

    const [getSelectDate, setSelectDate] = useState(false);


   const handleChangeText = (text) => {
     // console.log("kdnlfglk",text.target.value)

      setSelectDate(text.target.value)
     }


     const exportToCSV = () => {

      const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

      setExcelModal(false)

      let fileName = getSelectDate
      let getSelectDateDays = moment(getSelectDate).add(1, 'days').format('YYYY/MM/DD')

      console.log("klsdmkşlf",getSelectDate,getSelectDateDays)

    const data2 =  users.filter(x=> x.Created_DateTime > getSelectDate && x.Created_DateTime < getSelectDateDays).map(elt=> [
      elt.Users.NameSurname,
        elt.Users.Users_TCNumber,
        elt.Users.GsmNumber,
         "TR" + elt.DynamicForm_Success_Value
        ]);

      const ws = XLSX.utils.json_to_sheet(data2);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension)

    }
    
  return (
    <React.Fragment>
      
      <DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        show={getExcelModal}
        onCloseClick={() => setExcelModal(false)}
      />


      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
     <EcommerceOrdersModal getItemToggle={getItemToggle} isOpen={modal1} toggle={toggleViewModal1} />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetici" breadcrumbItem="Form Listesi" />
         
          <ExportToExcel apiData={data2} fileName={"fileName"} />

          <ExportToExcel2 onDownloadClick={exportToCSV} onClickExcel={onClickExcel} apiData={data2} fileName={"fileName"} />


          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    isPopupList={false}

                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Popup Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                      
                          <div className="d-flex">
                          <label htmlFor="resume">Düzenlenebilsin Mi?</label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                         
                        </div>
                        

                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
