import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link , useLocation} from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';


import {
  addDynamicForm2 as onAddDynamicForm2,
  getDynamicFormExtra as onGetDynamicFormExtra,
  updateynamicForm2 as onUpdateynamicForm2,
  deleteDynamicFormExtra as onDeleteDynamicFormExtra
} from "store/contacts/actions";

import {
  Title,
  Status,
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";


function EcommerceOrder() {
  const location = useLocation();

  const state = location.state;

  //meta title
  document.title = "Dinamik Form | MEGA - Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getIsActive, setActive] = useState(false);

  const [getContactEdit, setContactEdit] = useState(null);
  const [getDetailItem, setDetailItem] = useState(null);

  
  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ID: (getContactEdit && getContactEdit.ID) || '',
      title: (getContactEdit && getContactEdit.Title) || '',
      sort: (getContactEdit && getContactEdit.Sort) || ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Başlığı Giriniz"),
    }),
   
    onSubmit: (values) => {
      console.log("lknsndfkl",getDetailItem)
      if (isEdit) {
        let updateForm = {
          ID : getContactEdit.ID,
          Dynamic_Form_ID: getDetailItem.ID,
          DynamicType:getType,
          Title:values.title,
          Sort:values.sort,
          Required:getIsActive
        }
        // update order
        dispatch(onUpdateynamicForm2(updateForm));
        validation.resetForm();
      } else {
        let newForm = {
          Dynamic_Form_ID: getDetailItem.ID,
          DynamicType:getType,
          Title:values["title"],
          Sort:values["sort"],
          Required:getIsActive
        }

        // save new order
        dispatch(onAddDynamicForm2(newForm));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.dynamicformextra,
  }));

  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetDynamicFormExtra(state._item.ID));

      console.log("klnsdkclf",state._item)
      setDetailItem(state._item)
      setIsEdit(false);
    }
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setOrder(order);

    setIsEdit(true);
    
    setContactEdit(order)
    setActive(order.Required)
    setWsText(order.DynamicType)
    setType(order.DynamicType)
    toggle()
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.ID) {
      dispatch(onDeleteDynamicFormExtra(order.ID));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Tipi',
        accessor: 'DynamicType',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
      {
        Header: 'Başlık',
        accessor: 'Title',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
   
      {
        Header: 'Sıralama',
        accessor: 'Sort',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
      {
        Header: 'Gerekli Mi?',
        accessor: 'Required',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },

    /* {
        Header: 'Detaylar',
        accessor: 'view',
        disableFilters: true,
        Cell: () => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={toggleViewModal}
            >
              Detayları Görüntüle
            </Button>);
        }
      },*/
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
                <Link
                to="/dynamicFormElementExtraExtra"
                className="text-danger"
                state={{ _item: cellProps.row.original }}
              
              >
               
              Alt Element Ekle
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

   
  const [getType, setType] = useState([]);

  const [getWsText, setWsText] = useState('Tek Seçim Alanı');


  const setChoice = arg => {
    setType(arg.target.value)
   }

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="Dinamik Liste" />



          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={false}
                    isAddDynamicFormElement={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Atölye Düzenle" : "Atölye Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">

                <div className="mb-3">
                      <Label className="form-label">Alan Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoice(text)}
                        value={
                          getWsText
                        }>

                           <option value={"Tek Seçim Aracı"} key={1}>Tek Seçim Aracı</option>
                           <option value={"Çoklu Seçim Aracı"} key={2}>Çoklu Seçim Aracı</option>
                           <option value={"Tarih Aracı"} key={3}>Tarih Aracı</option>
                           <option value={"Listeli Seçim"} key={4}>Listeli Seçim</option>
                           <option value={"Şifre Alanı"} key={5}>Şifre Alanı</option>
                           <option value={"Listeli Gösterim"} key={6}>Listeli Gösterim</option>
                           <option value={"Uzun Yazı Alanı"} key={7}>Uzun Yazı Alanı</option>
                           <option value={"Yazı Alanı"} key={8}>Yazı Alanı</option>
                           <option value={"Dosya Yükleme Alanı"} key={9}>Dosya Yükleme Alanı</option>
                           <option value={"Sayı Alanı"} key={10}>Sayı Alanı</option>
                           <option value={"Adres Alanı"} key={11}>Adres Alanı</option>
                           <option value={"Ad Soyad Alanı"} key={12}>Ad Soyad Alanı</option>
                           <option value={"Cinsiyet Seçimi"} key={13}>Cinsiyet Seçimi</option>
                           <option value={"TC Alanı"} key={14}>TC Alanı</option>
                           <option value={"İl Alanı"} key={15}>İl Alanı</option>
                           <option value={"Üniversite Alanı"} key={16}>Üniversite Alanı</option>
                           <option value={"Program Alanı"} key={17}>Program Alanı</option>
                           <option value={"Iban Alanı"} key={17}>Iban Alanı</option>

                          
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>
                    
                  
                    <div className="mb-3">
                      <Label className="form-label">Başlık</Label>
                      <Input
                        name="title"
                        type="text"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title ? true : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                      ) : null}
                    </div>

                    
                    <div className="mb-3">
                      <Label className="form-label">Sıra Numarası</Label>
                      <Input
                        name="sort"
                        type="number"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sort || ""}
                        invalid={
                          validation.touched.sort && validation.errors.sort ? true : false
                        }
                      />
                      {validation.touched.sort && validation.errors.sort ? (
                        <FormFeedback type="invalid">{validation.errors.sort}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="d-flex">
                   
                          <Label className="form-label">Aktiflik Durumu</Label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch2"
                              className="switch switch-info"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setActive(!getIsActive)
                              }
                            />
                            <label
                              htmlFor="square-switch2"
                              data-on-label="Aktif"
                              data-off-label="Pasif"
                            />
                          </div>
                       
                        </div>
                  
                 
                  
                  
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        KAYDET
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;