import React,{useState} from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import MultiSelectOption from "./MultiSelectOption";
import Select from "react-select";
import axios from "axios";
import moment from "moment";

const colourStyles = {
  menuList: styles => ({
      ...styles,
      background: 'papayawhip'
  }),
  option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused
          ? 'white'
          : isSelected
              ? 'black'
              : undefined,
      zIndex: 1
  }),
  menu: base => ({
      ...base,
      zIndex: 100
  })
  }

const EcommerceOrdersModal = props => {
  const { handleTeacher,_gelectedUser,_getTeacherSessionExtraUser,isOpen, toggle, toggleModal,handleChange,getUserList,getSelectUserList } = props
  const [getTeacher, setTeacher] = useState([]);
  const [getClassesExtra, setClassesExtra] = useState([]);

  const _deleteUser = async (ID) => {

    // const data = getTeacher_Session.filter(x=> x.Course_Schedules_ID == ID);
 
     console.log("ID1231",ID)
    axios.delete(`https://getjob.stechomeyazilim.info:5101/deteleClassExtra/${ID}`)
 
 }

  const postUser = async () => {
    var localDate = moment().local();

    console.log("kpsdfkl",getSelectUserList,_gelectedUser)
   // axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteTeacherSession/${getCourseSchedulesID}`)

    getSelectUserList.map(async(item, index) => {
      let jobSeekerExpreince = {
        Created_DateTime: localDate,
        Users_ID:item.value,
        IsActive:true,
        Teacher_Session_ID:_gelectedUser.ID
      }
      axios.post('https://getjob.stechomeyazilim.info:5101/postClassesExtra/send', jobSeekerExpreince)

  })

  const delay = 1000; // 2 saniye bekleyecek
  setTimeout(() => {
    // Burada çağırmak istediğiniz fonksiyonu yazabilirsiniz
    //console.log('Fonksiyon çağrıldı!');
    _getClassesExtra(_gelectedUser.ID)

  }, delay);

}

  const _getClassesExtra= async (item) => {
  
    console.log("lksccndfkl",item)
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getClassesExtraMega/select/${item}`)
    .then((res) => {

      if(res.data.length>0){
        setClassesExtra(res.data[0].Classes_Extra)

        console.log("nsdlkf1231",res.data[0].Classes_Extra)
      }

   
    })
  }


  const _handleTeacher= async (val) => {
    handleTeacher(val)

    setTeacher([val])
    _getClassesExtra(val.value)
    }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggleModal}
    >
      <div className="modal-content">
        <ModalHeader toggleModal={toggleModal} toggle={toggle}>Eks. Kontenjan</ModalHeader>
        <ModalBody>
       
        <div className="mb-3" style={{ zIndex: "1005" }}>
                          <label className="form-label">
                           Öğretmen Seçiniz
                          </label>
                          <Select
                          placeholder={"Öğretmen Seçiniz"}
                            value={_gelectedUser}
                            isMulti={false}
                            onChange={(val) => {
                              _handleTeacher(val);
                            }}
                            options={_getTeacherSessionExtraUser}
                            className="select2-selection"
                            styles={colourStyles}
                          />
                        </div>


    { getTeacher.length > 0 ? <div style={{ zIndex: "1001" }}>
        <MultiSelectOption handleChange={handleChange} fullData={getUserList} value={getSelectUserList} type={"user"}  />
        </div> : null}
 
        {getClassesExtra.length > props.getTotalExtraUser ||  getClassesExtra.length == props.getTotalExtraUser ?
        <div className="text-end">
                      <a
                    
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kontenjan Dolu
                      </a>
                    </div> :
                    <div className="text-end">
                      <button
                      onClick={()=> postUser()}
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Ekle
                      </button>
                    </div>}

          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                <tr>
                  <th scope="col">Kul. İsmi</th>
                  <th scope="col">İşlemler</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                 
                 
                  <th scope="row">
                    <div>
                    {getClassesExtra.map((item, index) => (
                      <div className="d-flex" key={index}>
                        <h6 key={index} className="text-danger border-bottom text-truncate font-size-14">{index+1} - </h6>
                       <h5 key={index} className="pl-5 border-bottom text-truncate font-size-14"> {item.Users.NameSurname}</h5>
                       </div>
                        ))}
                    </div>
                  </th>


                  <th scope="row">
                   
                    {getClassesExtra.map((item, index) => (
                       <div key={index}>
                       <button
                       onClick={()=> _deleteUser(item.ID)}
                       
                       type="submit"
                       className="m-2 btn btn-danger save-user"
                     >
                     Sil
                       </button>
                       </div>
                        ))}
                   
                  </th>


                </tr>
           
               
             
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Toplam:</h6>
                  </td>
                  <td>
                  {getClassesExtra.length} / {props.getTotalExtraUser}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggleModal}>
            Kapat
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EcommerceOrdersModal
