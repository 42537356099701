import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Number,Name, Status, Date } from "./contactlistCol";
import Dropzone from 'react-dropzone-uploader';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getDynamicForm as onGetDynamicForm,
  addDynamicForm as onaddDynamicForm,
  updateDynamicForm as onUpdateDynamicForm,
  deleteDynamicFormSuccessUser as onDeleteDynamicFormUser,
} from "store/contacts/actions";

import { isEmpty } from "lodash";
import moment from "moment";

//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {

  //meta title
  document.title = "Dinamik Formlar | MEGA ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEdit, setEdit] = useState();
  const [getIsEditFormUser, setIsEditFormUser] = useState();

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (getEdit && getEdit.Dynamic_Form_Name) || "",
      startAge: (getEdit && getEdit.Dynamic_Form_StartAge) || "",
      finishAge: (getEdit && getEdit.Dynamic_Form_FinishAge) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Lütfen Adınızı Giriniz"),
      startAge: Yup.string().required("Başlangıç Yaşınızı Giriniz"),
      finishAge: Yup.string().required("Bitiş Yaşınızı Giriniz")
    }),
    onSubmit: values => {
      var localDate = moment().local();

      if (isEdit) {
        const updateUser = {
          ID: getEdit.ID,
          Dynamic_Form_Name:values.name,
          Dynamic_Form_StartAge: values.startAge,
          Dynamic_Form_FinishAge: values.finishAge,
          Dynamic_Form_IsMeram: getIsMeram,
          Dynamic_Form_IsActive: getActive,
          Dynamic_Form_Banner:getFileName
                };
                console.log("updateUser",updateUser)
        // update user
        dispatch(onUpdateDynamicForm(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Dynamic_Form_Name:values["name"],
          Dynamic_Form_StartAge: values["startAge"],
          Dynamic_Form_FinishAge: values["finishAge"],
          Dynamic_Form_IsMeram: getIsMeram,
          Dynamic_Form_IsActive: getActive,
          Dynamic_Form_CreatedDateTime: localDate,
          Dynamic_Form_Banner:getFileName
        };
        // save new user
        dispatch(onaddDynamicForm(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.dynamic,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getEditForm, setEditForm] = useState(false);
  const [getActive, setActive] = useState(false);
  const [getIsMeram, setIsMeram] = useState(false);

  const [getModalFormUser, setModalFormUser] = useState(false);

  

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Form Adı",
        accessor: "Dynamic_Form_Name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
  
      {
        Header: "Aktif Başvuru",
        accessor: "DynamicForm_Success_User.length",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return(
          <div>
             <Number {...cellProps} />
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleFormUserClick(userData);
                }}
              >
              
                Listele
              </Link>
          </div>)
        },
      },
      
      {
        Header: "Durum",
        accessor: "Dynamic_Form_IsActive",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: 'Kayıt Tarihi',
        accessor: 'Dynamic_Form_CreatedDateTime',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
                <Link
                to="/addDynamicFormElement"
                className="text-danger"
                state={{ _item: cellProps.row.original }}
              >
              
                Element Ekle
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetDynamicForm());
      setIsEdit(false);
   }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const handleUserClick = arg => {
    const user = arg;
    setEditForm(user)
    setContact(user);
    setIsEdit(true);

    setActive(user.Dynamic_Form_IsActive)
    setIsMeram(user.Dynamic_Form_IsMeram)
    setFileName(user.Dynamic_Form_Banner)

    setEdit(user)
    toggle();
  };
  

  const [getIsFormList, setIsFormList] = useState([]);

  const handleFormUserClick = arg => {
    const user = arg;
  
    setIsEditFormUser(true);

    setIsFormList(user.DynamicForm_Success_User);

    toggleFormUser();
  };

  const toggleFormUser = () => {
    setModalFormUser(!getModalFormUser);
  }


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteDynamicFormUser(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const [getFileName, setFileName] = useState('');

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
   
  // handle the status of the file upload
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="DİNAMİK FORMLAR" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddDynamicForm={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

<Modal isOpen={getModalFormUser} toggle={toggleFormUser}>
                    <ModalHeader toggle={toggleFormUser} tag="h4">
                    Kullanıcı Listesi
                    </ModalHeader>
                    <ModalBody>
                    
                        <Row>
                          <Col xs={12}>
                          
                           {getIsFormList.map((item, index) => {
                              return(
                                <div key={index} className="mb-3">
                              <Label className="form-label">{item.Users.NameSurname}</Label>
                              </div>
                              )})}
                          
                            
                           
                         
                         
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                 onClick={() => {
                                 
                                  toggleFormUser();
                                }}
                                className="btn btn-success save-user"
                              >
                                Kapat
                              </button>
                            </div>
                          </Col>
                        </Row>
                    
                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Dinamik Formu Düzenle" : "Dinamik Form Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Form Adı</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Form Adı"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Başlangıç Yaşı</Label>
                              <Input
                                name="startAge"
                                label="startAge"
                                type="text"
                                placeholder="Başlangıç Yaşını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.startAge || ""}
                                invalid={
                                  validation.touched.startAge &&
                                    validation.errors.startAge
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.startAge &&
                                validation.errors.startAge ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.startAge}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Bitiş Yaşı</Label>
                              <Input
                                name="finishAge"
                                label="finishAge"
                                type="text"
                                placeholder="Mail Adresini Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.finishAge || ""}
                                invalid={
                                  validation.touched.finishAge &&
                                    validation.errors.finishAge
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.finishAge &&
                                validation.errors.finishAge ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.finishAge}
                                </FormFeedback>
                              ) : null}
                            </div>
                           
                            <div className="mb-3">
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Görsel(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          inputContent="Dosya Yükle"
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                <Row>
                <label htmlFor="resume"> Mevcut Eklenen Resim</label>

                <Col xs={12} className="mb-3">
                            <img
                  className=" avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' +  getFileName}
                  alt=""
                />
                          </Col>
                </Row>

                          </Col>
                </div>

                            <div className="d-flex">
                  
                          <Label className="form-label">Aktiflik Durumu</Label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch2"
                              className="switch switch-info"
                              defaultChecked={getActive}
                              onChange={() =>
                                setActive(!getActive)
                              }
                            />
                            <label
                              htmlFor="square-switch2"
                              data-on-label="Aktif"
                              data-off-label="Pasif"
                            />
                          </div>
                       
                        </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
