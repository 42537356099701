import PropTypes from 'prop-types'
import React from "react"
import { Modal, ModalBody, Label,Input } from "reactstrap"

const DeleteModal = ({ show, onDownloadClick, onCloseClick,handleChange }) => {



  return (
    <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
         
          <p className="text-muted font-size-16 mb-4">Tarih Seçiniz</p>

          <div className="mb-3">
                      <Input
                        name="startdate"
                        type="date"
                        // value={orderList.orderdate || ""}
                        onChange={handleChange}
                      />
                    
                    </div>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-danger" onClick={onDownloadClick}>İndir</button>
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Kapat</button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default DeleteModal
