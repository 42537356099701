import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button, Input, CardBody } from "reactstrap";

export const ExportToHeader = ({ apiData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    // Başlık satırını oluştur
    const header = [fileName];

    // Veriyi çalışma sayfasına dönüştür
    const apiDataRows = apiData;

    // Başlık satırını ve veriyi birleştirerek çalışma sayfasını oluştur
    const ws = XLSX.utils.aoa_to_sheet([header, ...apiDataRows]);

    // A1'den F1'e kadar birleştirme
    ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }];

    // Başlık için özel stilleri belirleme
    ws['A1'].s = {
      font: { color: { rgb: 'FF0000' }, bold: true, size: 14, name: 'Arial' },
      fill: { fgColor: { rgb: 'FFFF00' } },
    };

    // Çalışma kitabını oluştur
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    // Excel tamponunu oluştur
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Veriyi Blob olarak oluştur
    const data = new Blob([excelBuffer], { type: fileType });

    // Blob'u dosya olarak kaydet
    FileSaver.saveAs(data, fileName + fileExtension);
  };


  return (
    <Button
    type="button"
    color="success"
    className="btn  mb-2 me-2"
    onClick={(e) => exportToCSV(apiData, fileName)}
  >
    <i className="mdi mdi-export me-1" />
    Excel
  </Button>
  );
};