import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Email, Date } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import Dropzone from 'react-dropzone-uploader';

import {
  getTeacherMega  as onGetTeacher,
  addNewTeacher as onAddNewTeacher,
  updateTeacher as onUpdateTeacher,
  deleteTeacher as onDeleteTeacher,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import axios from 'axios';

import {ExportToExcel} from '../../../ExportToExcel'
import jsPDF from "jspdf";
import "jspdf-autotable";


const ContactsList = props => {

  //meta title
  document.title = "Öğretmen Listesi | MEGA ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEditContactTeacher, setEditContactTeacher] = useState();
  const [getIsActive, setIsActive] = useState(false);

  const [getFileName, setFileName] = useState();
  const [getUserType, setUserType] = useState([]);
  const [getChoice, setChoice] = useState();

  
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
   
  // handle the status of the file upload
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)
        }
      }
    }
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (getEditContactTeacher && getEditContactTeacher.NameSurname) || "",
      gsmnumber: (getEditContactTeacher && getEditContactTeacher.GsmNumber) || "",
      mailadress: (getEditContactTeacher && getEditContactTeacher.MailAdress) || '',
      password: (getEditContactTeacher && getEditContactTeacher.Users_Password) || "",
      registerDate: (getEditContactTeacher && getEditContactTeacher.CreatedDateTime) || "",      
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Adınızı Giriniz"),
     /* mailadress: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Mail Formatında Giriniz"
      ).required("Lütfen mail adresini giriniz"), */
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          ID: contact.ID,
          NameSurname: values.name,
          GsmNumber: values.gsmnumber,
          MailAdress: values.mailadress,
          Users_Password: values.password,
          Users_IsActive: getIsActive,
          Users_TCNumber:values.tcNumber,
          UsersPhoto : getFileName,
          Users_Type_ID: getChoice
        };
        // update user
        dispatch(onUpdateTeacher(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        var localDate = moment().local();

        const newUser = {
          NameSurname: values["name"],
          GsmNumber: values["gsmnumber"],
          MailAdress: values["mailadress"],
          Users_Password: values["password"],
          Users_IsActive: getIsActive,
          Users_Type_ID:3,
          Users_TCNumber:values["tcNumber"],
          UsersPhoto : getFileName,
          CreatedDateTime: localDate
        };
        // save new user
        dispatch(onAddNewTeacher(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.teacher,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);



  const _getAllAuth = async () => {
    try {
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersTypeMega/select/`)
    .then((res) => {
      setUserType(res.data)
    })
  } catch (err) {
    console.log("_getAllAuth",err)
  }
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.UsersPhoto ? (
              <div className="avatar-xl">
              { cellProps.NameSurname != null ? <span className="avatar-title ">
                  {cellProps.NameSurname.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.UsersPhoto}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Adı Soyadı",
        accessor: "NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Mail Adresi",
        accessor: "MailAdress",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "GSM",
        accessor: "GsmNumber",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: 'Kayıt Tarihi',
        accessor: 'CreatedDateTime',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
               <Link
                to="/lessonsTimeAdmin"
                className="text-success"
                state={{ _item: cellProps.row.original }}>
                 Ders Programları
              </Link>

               <Link
                to="/teacherLessonsTime"
                className="text-danger"
                state={{ _item: cellProps.row.original }}>
                 Seansları
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetTeacher());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  useEffect(() => {
    _getAllAuth()
  }, []);

  

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setEditContactTeacher(user)
    setContact(user);
    setIsEdit(true);
    setIsActive(user.Teacher_IsActive)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteTeacher(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };


  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const _setChoice= arg => {
    setChoice(arg.target.value)
    console.log("lk123kl",arg.target.value)
   }

   const [getText, setText] = useState(null);

   const [num, setNum] = useState(0);

   const fileName = "myfile" + num; // here enter filename for your excel file
 



const getBase64 = async(file) => {
  const base64 = await fetch('https://megasubdomain.stechomeyazilim.info/dataFile-1686901065006.jpeg')
  .then(response => response.blob())
  .then(blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((res) => {
      reader.onloadend = () => {
      res(reader.result);
    }})
  })
}

   const exportPDF = async() => {
     const unit = "pt";
     const size = "A4"; // Use A1, A2, A3 or A4
     const orientation = "portrait"; // portrait or landscape
 
     const marginLeft = 40;
     const doc = new jsPDF(orientation, unit, size);
 
     doc.setFontSize(15);
 
     const title = "Atölye Seansları Listesi";
     const headers = [["İsim Soyisim", "Foto","Foto"]];
 
     const data = users.map(elt=> [elt.NameSurname, 'https://megasubdomain.stechomeyazilim.info/' +  elt.UsersPhoto]);


     getBase64()

     let content = {
       startY: 50,
       head: headers,
       body: data,
       didDrawCell: function(data) {
        console.log("sdjkfnsdf",data.column.index)
        if (data.column.index === 2 && data.cell.section === 'body') {
           var dim = data.cell.height - data.cell.padding('vertical');
           var textPos = data.cell.textPos;

           
           doc.addImage('https://megasubdomain.stechomeyazilim.info/dataFile-1686901065006.jpeg', 'JPEG', 15, 40, 180, 160);
          }
      }
     };
 
     doc.text(title, marginLeft, 40);
     doc.autoTable(content);
     doc.save("report.pdf")


   }
 

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Kullanıcı" breadcrumbItem="Öğretmen Listesi" />


          <ExportToExcel apiData={users} fileName={fileName} />

<Button
type="button"
color="danger"
className="btn  mb-2 me-2"
onClick={exportPDF}>
<i className="mdi mdi-export me-1" />
     PDF
</Button>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddTeacher={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Öğretmen Düzenle" : "Öğretmen Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >      

                        <Row>
                          <Col xs={12}>

                          <div className="mb-3">
                      <Label className="form-label">Kullnıaıcı Tipi Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => _setChoice(text)}
                        value={
                          getText
                        }>
                          
                         {getUserType.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Users_Type_Title}</option>
                        ))}
                                             
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>

                            <div className="mb-3">
                              <Label className="form-label">Ad-Soyad</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Ad ve Soyad"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">GSM</Label>
                              <Input
                                name="gsmnumber"
                                label="Gsm"
                                type="text"
                                placeholder="GSM Numaranızı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gsmnumber || ""}
                                invalid={
                                  validation.touched.gsmnumber &&
                                    validation.errors.gsmnumber
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.gsmnumber &&
                                validation.errors.gsmnumber ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.gsmnumber}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Mail</Label>
                              <Input
                                name="mailadress"
                                label="Email"
                                type="email"
                                placeholder="Mail Adresini Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mailadress || ""}
                                invalid={
                                  validation.touched.mailadress &&
                                    validation.errors.mailadress
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.mailadress &&
                                validation.errors.mailadress ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.mailadress}
                                </FormFeedback>
                              ) : null}
                            </div>
                           
                      

                            <div className="mb-3">
                              <Label className="form-label">Şifre</Label>
                              <Input
                                name="password"
                                label="Şifre"
                                type="text"
                                placeholder="Şifrenizi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                    validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>

                   

                            <div className="mb-3">
                    <label htmlFor="resume">Kullanıcı Görseli</label>
                         <Dropzone
                         maxFiles={1}
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={true}
          PreviewComponent={Preview}
          accept="image/*"
        />
                    </div>

                            <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>


                            <div className="mb-3">
                          
                          <Label className="form-label">Kayıt Tarihi</Label>

                    <input
                      className="form-control"
                      type="date"
                      disabled={true}
                     // value={contactEdit && moment(contactEdit.Users_Birthday).format('yyyy-mm-DD')}
                      defaultValue={moment(validation.values.registerDate).format('yyyy-MM-DD')}
                      id="example-date-input"
                    />
                 
                </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
