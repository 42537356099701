import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getLocationForm as onGetLocationMega,
  addLocation as onAddLocation,
  updateLocation as onUpdateLocation,
  deleteLocation as onDeleteLocation,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {

  //meta title
  document.title = "Tesis Bilgileri | MEGA ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEdit, setEdit] = useState();
  const [getEditForm, setEditForm] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (getEdit && getEdit.Location_Workshop_Title) || "",
      lat: (getEdit && getEdit.Lat) || null,
      long: (getEdit && getEdit.Long) || null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Lütfen Tesis Başlığını Giriniz"),
    }),
    onSubmit: values => {

      console.log("lsndfkl",getEdit)
      if (isEdit) {
        const updateUser = {
          ID: getEdit.ID,
          Location_Workshop_Title:values.title,
          Lat: values.lat,
          Long: values.long,
                };
                console.log("updateUser",updateUser)
        // update user
        dispatch(onUpdateLocation(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Location_Workshop_Title:values["title"],
          Lat: values["lat"],
          Long: values["long"],
        }

        console.log("newUser123",newUser)
        // save new user
        dispatch(onAddLocation(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.location,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Tesis Adı",
        accessor: "Location_Workshop_Title",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
  
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
             
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetLocationMega());
      setIsEdit(false);
   }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setEditForm(user)
    setContact(user);
    setIsEdit(true);

   

    setEdit(user)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteLocation(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Tesis Bilgileri" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddLocation={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Tesis Bilgilerini Düzenle" : "Tesis Bilgilesi Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Tesis Adı</Label>
                              <Input
                                name="title"
                                type="text"
                                placeholder="Tesis Adı"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Konum Enlem</Label>
                              <Input
                                name="lat"
                                label="Konum Enlem"
                                type="text"
                                placeholder="Konum Enlem"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lat || ""}
                                invalid={
                                  validation.touched.lat &&
                                    validation.errors.lat
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lat &&
                                validation.errors.lat ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lat}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Konum Boylam</Label>
                              <Input
                                name="long"
                                label="Konum Boylam"
                                type="text"
                                placeholder="Konum Boyla"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.long || ""}
                                invalid={
                                  validation.touched.long &&
                                    validation.errors.long
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.long &&
                                validation.errors.long ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.long}
                                </FormFeedback>
                              ) : null}
                            </div>
                           
                      

                
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
