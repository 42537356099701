import PropTypes from 'prop-types'
import React,{useMemo} from "react"
import { Modal, ModalBody,Button} from "reactstrap"
import TableContainerAuth from "../../components/Common/TableContainerAuth";
import { NameSurname} from "../../pages/Contacts/AuthorityList/contactlistCol";

const NewModal = ({ show, getAllAuth,onCloseClick,contactEdit1}) => {

  const onClickDeleteAuth = (item) => {

    console.log("lksnd123flk",modalAuth.userTypeID)
    axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteUserAuthority/${item.ID}`).then(async(res) => {

    //await _getAllAuth(getUserType)

    console.log("res12321",res)
    })

      //setUserAuth(filteredData)


  };


  const onClickAddAuth = async(item) => {
    console.log("11getAuth11List",contactEdit1)

    if (contactEdit1 && contactEdit1.ID) {
   

    let data = {
      Users_Authority_Title:item.Authority_Text,
      Authority_ID:contactEdit1.ID,
      Users_Type_ID:modalAuth.userTypeID
    }
    //  setUserAuth(filteredData)

    await axios.post(`https://getjob.stechomeyazilim.info:5101/postUserAuthority/send`, data).then(async(res) => {

    //await _getAllAuth(modalAuth.userTypeID)

    console.log("res12321",res)
    
    })
  }
    
  };

  const columnsAuth = useMemo(
    () => [
      {
        Header: "Yetki Adı",
        accessor: "Authority_Text",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
              cellProps.row.original.Users_Authority.length > 0 ?  
            <div className="d-flex gap-3">
            <Button
              className="text-danger"
              onClick={() => {
                const userData = cellProps.row.original;
                onClickDeleteAuth(userData);
              }}
            >
              Yetkiyi Al
            </Button>
          </div> :
           <div className="d-flex gap-3">
           <Button
          
             className="text-success"
             onClick={() => {
               const userData = cellProps.row.original;
               onClickAddAuth(userData);
             }}
           >
             Yetki Ver
           </Button>

         </div>
           
          )
        },
      },
    
    ],
    []
  );



  return (

    <Modal size="lg"   centered={true} isOpen={show} toggle={onCloseClick}>
                  
                    <div className="modal-content">
                    <ModalBody>
                                               
                  <TableContainerAuth
                    columns={columnsAuth}
                    data={getAllAuth}
                    isGlobalFilter={false}
                    isAddUserList={false}
                    customPageSize={15}
                    className="custom-header-css"
                  />
                         
                    </ModalBody>
                    </div>
                  </Modal>
                  

  )
}

NewModal.propTypes = {
  contactEdit1:PropTypes.any,
  toggleAuth:PropTypes.func,
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default NewModal
