import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link,useLocation } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import axios from 'axios';



import {
  addLessonsTime as onAddLessonsTime,
  getTeacherSessionMega as onGetTeacherSessionMega,
  updateLessonsTime as onUpdateLessonsTime,
  deleteLessonsTime as onDeleteLessonsTime
} from "store/contacts/actions";

import {
  Title
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";

function EcommerceOrder() {

  const location = useLocation();

  const state = location.state;
  //meta title
  document.title = "SEANSLAR | Mega - Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getWorkShopEdit, setWorkShopEdit] = useState(null);
 
  const [getCourseSchedules, setCourseSchedules] = useState([]);

  const [getWsText, setWsText] = useState(null);
  const [getTeacherList, setTeacherList] = useState([]);
  const [getIsActive, setIsActive] = useState(true);
  const [getSubdomain, setSubdomain] = useState([]);
  const [getChoiceSubdomainID, setChoiceSubdomainID] = useState();
  const [getCourseWorkshopSession, setCourseWorkshopSession] = useState([]);
  const [getChoiceCourseSchedulesID, setChoiceCourseSchedulesID] = useState(null);
  const [getTeacherID, setTeacherID] = useState();

  const [getIsActiveWorkshop, setIsActiveWorkshop] = useState(true);

  
  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ID: (getWorkShopEdit && getWorkShopEdit.ID) || '',

    },
    validationSchema: Yup.object({

    }),
    onSubmit: (values) => {
      if (isEdit) {
        console.log("sndfklsdmfş",state)
        const updateWorkshop = {
          ID: order ? order.ID : 0,
          Course_Schedules_ID:getChoiceCourseSchedulesID,
          Users_ID:getTeacherID,
        };
        // update order
        dispatch(onUpdateLessonsTime(updateWorkshop));
        validation.resetForm();
      } else {

        console.log("lsmndfklds",getIsActive,getChoiceCourseSchedulesID,getTeacherID)
        const newOrder = {
       //   Is_Active:getIsActive,
          Course_Schedules_ID:getChoiceCourseSchedulesID,
          Users_ID:getTeacherID,
        };
        // save new order

        if(getIsActiveWorkshop == true){
          _postTeacherWorkshop()
        }
        
        dispatch(onAddLessonsTime(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.teacherSession,
  }));




  const _getTeacherList = async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getTeacherMega/select/`)
    .then((res) => {
      setTeacherList(res.data)
    })
  }

  const _getSubDomain = async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getSUBDOMAINMega/select/`)
    .then((res) => {
      setSubdomain(res.data)
    })
  }


  

  useEffect(() => {
    console.log("sdlfkmsşd",state)
    _getTeacherList()
    _getSubDomain()
  }, []);

  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetTeacherSessionMega());
      setIsEdit(false);
    }
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setWorkShopEdit(order)
    setOrder(order);

    setIsActive(order.Is_Active)
    setIsEdit(true);
   // setWsText(order.Workshop_Sessions.Workshop_Sessions_Text + " " + order.Workshop.Workshop_Text)

    toggle()
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.ID) {
      dispatch(onDeleteLessonsTime(order.ID));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  
  const _setChoiceCourseSchedules = arg => {
    console.log("arg123",arg.target)
    setChoiceCourseSchedulesID(arg.target.value)
   }

  const _setChoice = arg => {
    _getCourseSchedulesTeacherMega(arg.target.value)
   }

  const _setChoiceSubdomain = arg => {
    setChoiceSubdomainID(arg.target.value)
    _getSearchSUBDOMAINMega(arg.target.value)
   }

   const _getSearchSUBDOMAINMega = async (ID) => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getSearchWorkshopSession/select/${ID}`)
    .then((res) => {
      setCourseWorkshopSession(res.data)
      console.log("lkdmsnklf",res.data)
    })
  }

  const _getCourseSchedulesTeacherMega = async (ID) => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCourseSchedulesTeacherMega/select/${ID}`)
    .then((res) => {
      setCourseSchedules(res.data)
      console.log("lkdmsnklf",res.data)
    })
  }



  const _postTeacherWorkshop = async () => {

    let jobSeekerExpreince = {
      Is_Active: true,
      Course_Schedules_ID:getChoiceCourseSchedulesID,
      Teacher_Workshop_Title:null,
      Teacher_Workshop_Tools:null,
      Users_ID:getTeacherID
    }
    axios.post('https://getjob.stechomeyazilim.info:5101/postTeacherWorkshopMega/send', jobSeekerExpreince)


  }





  const setChoiceTeacher = arg => {
    setTeacherID(arg.target.value)
   }

  const columns = useMemo(
    () => [
      {
        Header: 'Başvuru Dönemi',
        accessor: 'Course_Schedules.Workshop_Sessions.Workshop_Sessions_Text',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
   
   
      {
        Header: 'Atölye',
        accessor: 'Course_Schedules.Workshop.Workshop_Text',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
    
      {
        Header: 'Öğretmen Adı',
        accessor: 'Users.NameSurname',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },

    /* {
        Header: 'Detaylar',
        accessor: 'view',
        disableFilters: true,
        Cell: () => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={toggleViewModal}
            >
              Detayları Görüntüle
            </Button>);
        }
      },*/
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="Seans" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isAddSchedules={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Seans Düzenle" : "Seans Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">


                  <div className="mb-3">
                      <Label className="form-label">Öğretmen Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoiceTeacher(text)}
                        value={
                          getWsText
                        }>

                        {getTeacherList.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.NameSurname}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>


                    <div className="mb-3">
                      <Label className="form-label">Başvuru Alanını Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => _setChoiceSubdomain(text)}
                        value={
                          getWsText
                        }>

                        {getSubdomain.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.SUBDOMAIN_Title}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>

                  <div className="mb-3">
                      <Label className="form-label">Başvuru Dönemi Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => _setChoice(text)}
                        value={
                          getWsText
                        }>

                        {getCourseWorkshopSession.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Workshop_Sessions_Text}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>


                    <div className="mb-3">
                      <Label className="form-label">Form Başlığı Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => _setChoiceCourseSchedules(text)}
                        value={
                          getWsText
                        }>

                        {getCourseSchedules.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Workshop_Sessions.Workshop_Sessions_Text + " " + item.Course_Schedules_Day + " " + item.Course_Schedules_StartTime + " " + item.Course_Schedules_FinishTime + " " + item.Workshop.Workshop_Text}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>

               
              
                    <Label className="form-label">Aktiflik Durumu</Label>

                    <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                  

                          <Label className="form-label">Otomatik Ders Programı Oluştur</Label>

                          <div className="square-switch">


                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActiveWorkshop}
                              onChange={() =>
                                setIsActiveWorkshop(!getIsActiveWorkshop)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                          
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        KAYDET
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;