import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Date, Status } from "./contactlistCol";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import "./index.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import 'react-dropzone-uploader/dist/styles.css'

import {
  getUserNull as onGetUserNull,
  addPopup as onAddPopup,
  updateFormSuccessUserNull as onUpdateFormSuccessUserNull,
  deleteDynamicFormSuccessUser as onDeleteDynamicFormSuccessUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import {ExportToExcel} from '../../ExportToExcel'

//redux
import { useSelector, useDispatch } from "react-redux";

import jsPDF from "jspdf";
import "jspdf-autotable";

const ContactsList = props => {

  //meta title
  document.title = "Eksik Form | MEGA ADMIN PANELI";


  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setContactEdit] = useState();
  const [getIsActive, setIsActive] = useState(false);
  const [getFileName, setFileName] = useState(null);

  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
   

    },
    validationSchema: Yup.object({
    

    }),
    onSubmit: values => {
      console.log("lknsdlkf",contact.ID,getIsActive)
      if (isEdit) {
        const updatePopup = {
          ID: contact.ID,
          EditForm: getIsActive
        };
        // update user
        dispatch(onUpdateFormSuccessUserNull(updatePopup));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Popup_Name: values["name"],
          Popup_IsActive:getIsActive,
          Popup_PhotoLink:getFileName,
          Popup_Weblink:values["link"],
        };
        // save new user
        dispatch(onAddPopup(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [getItemToggle, setItemToggle] = useState(null);

  const toggleViewModal1 = () => {    
    setModal1(!modal1)};

  const toggleViewModal = (item) => {
    setItemToggle(item)
    console.log("sndflkdnsfk",item)
    
    setModal1(!modal1)};

  const { users } = useSelector(state => ({
    users: state.contacts.nullUsers,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modal1, setModal1] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },

      {
        Header: "Kullanıcı Adı",
        accessor: "NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "TC",
        accessor: "Users_TCNumber",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "GSM",
        accessor: "GsmNumber",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Üyelik Tarihi",
        accessor: "CreatedDateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },
        
    
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUserNull());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;

    setContact(user);
    setContactEdit(user)
    setIsEdit(true);

    if(user.EditForm == 0){
      setIsActive(false)
    }else{
      setIsActive(true)
    }
   
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteDynamicFormSuccessUser(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Öğrenci Form Sonuçları";
    const headers = [["Soru", "Cevap", "Veri Tipi"]];

    const data = users != null ? users.map(elt=> [elt.Dynamic_Form_Extra.Title, elt.DynamicForm_Success_Value, elt.Dynamic_Form_Extra.DynamicType, elt.Users.Users_TCNumber, elt.Users.GsmNumber, elt.Users.NameSurname]) : null;


    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  }


  const data2 =  users.map(elt=> [
    elt.NameSurname,
    elt.Users_TCNumber,
    elt.GsmNumber,
    ]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
     <EcommerceOrdersModal getItemToggle={getItemToggle} isOpen={modal1} toggle={toggleViewModal1} />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetici" breadcrumbItem="Form Listesi" />
         

          <ExportToExcel apiData={data2} fileName={"fileName"} />

          <Button
    type="button"
    color="danger"
    className="btn  mb-2 me-2"
    onClick={exportPDF}>
       <i className="mdi mdi-export me-1" />
                     PDF
                </Button>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    isPopupList={false}

                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Popup Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                      
                          <div className="d-flex">
                          <label htmlFor="resume">Düzenlenebilsin Mi?</label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                         
                        </div>
                        

                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
