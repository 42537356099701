import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import axios from 'axios';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


import {
  addCourse as onAddCourse,
  getCourseSchedulesMega as ongetCourseSchedulesMega,
  updateCourseSchedules as onUpdateCourseSchedules,
  deleteCourseSchedules as onDeleteCourseSchedules,
} from "store/contacts/actions";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs';
import MultiSelectOption from "./MultiSelectOption";
import {ExportToExcel} from '../../../ExportToExcel'
import Select from "react-select";

import "dayjs/locale/tr";


import {
  OrderId,
  BillingName,
  Date,
  Total,
  Status
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Badge,
} from "reactstrap";
import moment from "moment";

import jsPDF from "jspdf";
import "jspdf-autotable";

function EcommerceOrder() {
  const [num, setNum] = useState(0);

  const fileName = "myfile" + num; // here enter filename for your excel file

  const colourStyles = {
    menuList: styles => ({
        ...styles,
        background: 'papayawhip'
    }),
    option: (styles, {isFocused, isSelected}) => ({
        ...styles,
        background: isFocused
            ? 'red'
            : isSelected
                ? 'black'
                : undefined,
        zIndex: 1
    }),
    menu: base => ({
        ...base,
        zIndex: 100
    })
    }

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Atölye Seansları Listesi";
    const headers = [["Gün", "Kontenjan", "Ekstra Kontenjan", "Atölye", "Başvuru Dönemi"]];

    const data = orders.map(elt=> [elt.Course_Schedules_Day, elt.Number_OfQuotas, elt.Number_OfQuotas_Extra, elt.Workshop.Workshop_Text, elt.Workshop_Sessions.Workshop_Sessions_Text]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  }

  //meta title
  document.title = "Atölye Seansları | MEGA Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [getIsEditTeacher, setIsEditTeacher] = useState(false);
  const [modalTeacher, setModalTeacher] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getWsID, setWsID] = useState(null);
  const [getWsText, setWsText] = useState(null);
  
  const [getWorkshopText, setWorkshopText] = useState(null);
  const [getLocationWorkshop, setLocationWorkshop] = useState(null);

  const [getWorksDay, setWorksDay] = useState(null);

  const [getTotalExtraUser, setTotalExtraUser] = useState(null);
  const [getTeacherSessionExtraUser, setTeacherSessionExtraUser] = useState([]);

  
  
  const [getWorkshopSessionData, setWorkshopSessionData] = useState([]);
  const [getWorkshopData, setWorkshopData] = useState([]);
  const [getLocation, setLocation] = useState([]);
  const [getDynamicForm, setDynamicForm] = useState([]);


  const [getWorkshopSessionEditList, setWorkshopSessionEditList] = useState();
  const [getWorkshopID, setWorkshopID] = useState();
  const [getIsActive, setIsActive] = useState(true);
  const [getIsActivePrice, setIsActivePrice] = useState(false);

  const [getIsMePayment, setIsMePayment] = useState(false);

  const [getDays, setDays] = useState(false);
  const [getLocationID, setLocationID] = useState(false);

  const [getChoiceDynamic, setChoiceDynamic] = useState(null);
  const [getSelectedmessageData, setSelectedmessageData] = useState(null);

  const [getTeacherList, setTeacherList] = useState([]);
  const [getSelectTeacherList, setSelectTeacherList] = useState([]);
  const [getCourseSchedulesID, setCourseSchedulesID] = useState([]);


  
  
  const [getUserList, setUserList] = useState([]);
  const [getSelectUserList, setSelectUserList] = useState([]);

  const [getIsActiveForm, setIsActiveForm] = useState(false);


  const [selectedMultiDay, setselectedMultiDay] = useState([]);

  const [getDaysModal, setDaysModal] = useState([]);
  const [getIsDaysVisible, setIsDaysVisible] = useState(false);


  const [getClass, setClass] = useState([]);
  const [getClassUser, setClassUser] = useState([]);

  const [getIsClassVisible, setIsClassVisible] = useState(false);
  const [getClassCapacity, setClassCapacity] = useState(null);

  const [_gelectedUser, _selectedUser] = useState([]);

  const [getTime, setTime] = useState([]);

  
const optionDay = [
  {
    label: "Günler",
    options: [
      { label: "Pazartesi", value: "Pazartesi" },
      { label: "Salı", value: "Salı" },
      { label: "Çarşamba", value: "Çarşamba" },
      { label: "Perşembe", value: "Perşembe" },
      { label: "Cuma", value: "Cuma" },
      { label: "Cumartesi", value: "Cumartesi" },
      { label: "Pazar", value: "Pazar" }

    ]
  }
]

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ID: (getWorkshopSessionEditList && getWorkshopSessionEditList.ID) || '',
      numberOfQutas: (getWorkshopSessionEditList && getWorkshopSessionEditList.Course_Schedules.Number_OfQuotas) || '',
      numberOfQutasExtras: (getWorkshopSessionEditList && getWorkshopSessionEditList.Course_Schedules.Number_OfQuotas_Extra) || 0,
      price: (getWorkshopSessionEditList && getWorkshopSessionEditList.Course_Schedules.Price) || null,
      IsMePayment: (getWorkshopSessionEditList && getWorkshopSessionEditList.Course_Schedules.IsMePayment) || '',
      ageRange:(getWorkshopSessionEditList && getWorkshopSessionEditList.Course_Schedules.Age_Range) || '',
    },
    validationSchema: Yup.object({
      numberOfQutas: Yup.string().required("Lütfen Kontenjan Giriniz")
    }),
    onSubmit: async(values) => {
      if (isEdit) {
    

        const updateOrder = {
          ID: order ? order.Course_Schedules.ID : 0,
          Number_OfQuotas_Extra:  values.numberOfQutasExtras,
          Number_OfQuotas: values.numberOfQutas,
          Is_Active:getIsActive,
          Workshop_Session_ID:getWsID,
          Workshop_ID:getWorkshopID,
          Location_Workshop_ID:getLocationID,
          Dynamic_Form_ID:getChoiceDynamic,
          Price:values.price,
          IsMePayment:getIsActivePrice,
          Age_Range:values.ageRange
        }
        dispatch(onUpdateCourseSchedules(updateOrder));
        validation.resetForm();
      } else {   

        const newOrder = {
          Is_Active:getIsActive,
          Number_OfQuotas: values["numberOfQutas"],
          Workshop_Session_ID:getWsID,
          Workshop_ID:getWorkshopID,
          Number_OfQuotas_Extra:values["numberOfQutasExtras"],
          Location_Workshop_ID:getLocationID,
          Dynamic_Form_ID:getChoiceDynamic,
          Price:values["price"],
          IsMePayment:getIsActivePrice,
          Age_Range:values["ageRange"]
        }


        await axios.post(`https://getjob.stechomeyazilim.info:5101/addCourseMega/send`, newOrder).then(async(res) => {
      

        const newOrderDay = {
          Sheduled_DayTitle:null,
          StartTime:null,
          FinishTime:null,
          Course_Schedules_ID:res.data.value[0].ID
        }

        await axios.post(`https://getjob.stechomeyazilim.info:5101/addCourseCourseSheduledDay/send`, newOrderDay).then(async(res) => {
      
    //    console.log("re12312s",res)


      })


      })

        

        //dispatch(onAddCourse(newOrder))    

        setTimeout(() => {
          dispatch(ongetCourseSchedulesMega());
        }, 1000);
      
        validation.resetForm()
      }
      toggle();
    },
  });



  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.workshopSession,
  }));

  
  
  const _deleteClassUser = async (ID) => {

    // const data = getTeacher_Session.filter(x=> x.Course_Schedules_ID == ID);
 
    axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteClassesMega/${ID}`)
 
    const delay = 1000; // 2 saniye bekleyecek
    setTimeout(() => {
      // Burada çağırmak istediğiniz fonksiyonu yazabilirsiniz
      _getClassesExtra(getSelectedmessageData.Course_Schedules.Teacher_Session[0].ID)
  
    }, delay);

 }
  

const convertToUCS2 = (text) => {
  // TextEncoder kullanılarak UTF-16'ya çevirme
  const encoder = new TextEncoder('utf-16');
  const uint8Array = encoder.encode(text);

  // Uint8Array'yi hexadecimal formatta yazdırma
  const hexArray = Array.from(uint8Array, byte => byte.toString(16).padStart(2, '0'));
  const hexString = hexArray.join('').toUpperCase();

  return hexString;
};


 const sendMessage = async(name,day,starttime,finishtime,user)=> {

  await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersIDMega/select/${getSelectUserList[0].value}`)
  .then(async(res) => {
 
  const metin = `Değerli öğrencimiz ${res.data[0].NameSurname} yeni nesil eğitim kapsamında başvurduğunuz ${name} atölyesi ${starttime} - ${finishtime}}`

  const ucs2Metin = (metin);


  let newData = {"GSM" : '9' + res.data[0].GsmNumber, 
  "Message" :  ucs2Metin}

  
  let data = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns="https://webservice.asistiletisim.com.tr/SmsProxy">
    <soapenv:Header/>
    <soapenv:Body>
      <sendSms>
        <requestXml>
          <![CDATA[
            <SendSms>
              <Username>megaapi</Username>
              <Password>Mg/*-8520</Password>
              <UserCode>6817</UserCode>
              <AccountId>3887</AccountId>
              <Originator>MERAM BLD</Originator>
              <SendDate></SendDate>
              <ValidityPeriod>60</ValidityPeriod>
              <MessageText>
                Değerli öğrencimiz ${user.NameSurname}
                Meram Gelişim Akademisi’ne Hoş Geldin!
                ${name} Atölyesi ${starttime} - ${finishtime} seansına kaydın başarıyla tamamlanmıştır.
                İlk Ders ${day} gün ${starttime} Saatte.
                Konum: https://maps.app.goo.gl/pJWN2HeRrpTT6ZFWA
              </MessageText>
              <IsCheckBlackList>0</IsCheckBlackList>
              <ReceiverList>
                <Receiver>9${user.GsmNumber}</Receiver>
              </ReceiverList>
            </SendSms>
          ]]>
        </requestXml>
      </sendSms>
    </soapenv:Body>
  </soapenv:Envelope>`;
  
  
  await axios.post(`https://getjob.stechomeyazilim.info:5101/sendSmsMega/send`, newData).then(async(res) => {
      
  })
  
})
    }

const _postUser = async () => {

  //console.log("askldsak",getSelectedmessageData.Course_Schedules.Workshop.Workshop_Text,getSelectedmessageData.Sheduled_DayTitle,getSelectedmessageData.StartTime,getSelectedmessageData.FinishTime)
  sendMessage(getSelectedmessageData.Course_Schedules.Workshop.Workshop_Text,getSelectedmessageData.Sheduled_DayTitle,getSelectedmessageData.StartTime,getSelectedmessageData.FinishTime,getSelectUserList)
  //console.log("sdfkl11mdksclşf",_gelectedUser)
  
  var localDate = moment().local();

  getSelectUserList.map(async(item, index) => {
    let jobSeekerExpreince = {
      Created_DateTime:localDate,
      Users_ID:item.value,
      IsActive:true,
      Teacher_Session_ID:_gelectedUser.ID
    }
    axios.post('https://getjob.stechomeyazilim.info:5101/addApply/send', jobSeekerExpreince)

    const delay = 1000; // 2 saniye bekleyecek
    setTimeout(() => {
      // Burada çağırmak istediğiniz fonksiyonu yazabilirsiniz
      _getClassesExtra(_gelectedUser.ID)
  
    }, delay);

   /*  getSelectUserList.map(async(item, index) => {
      setClass(oldArray => [...oldArray,{  Created_DateTime:localDate,
        Users_ID:item.value,
        IsActive:true,
        Teacher_Session_ID:_gelectedUser.ID}] )
   })*/

})

}




const postDay = async () => {
  try {

  if(order!=null){
   /* if(getDaysModal.length>0){
      axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteCourseSheduledDay/${order.ID} ve userID`)
    }*/

    getTime.map(async(item, index) => {
      let jobSeekerExpreince = {
       // Course_Schedules_ID:order.ID,
        Sheduled_DayTitle:item.day,
        StartTime:item.startTime,
        FinishTime:item.finishTime
      }

      await axios.patch(`https://getjob.stechomeyazilim.info:5101/patcCourseSheduledDay/${order.ID}`, jobSeekerExpreince)
      .then(async (res1) => {
        setDaysModal([jobSeekerExpreince])

        setIsDaysVisible(false)

        dispatch(ongetCourseSchedulesMega());

       //showToast('GetJob', "Hesabınız Pasif Durumdan Aktif Duruma Geçirildi!", 'success')
      })

  //    axios.post('https://getjob.stechomeyazilim.info:5101/patcCourseSheduledDay/insert', jobSeekerExpreince)

     
  })

  //_getDays()
  }
  if(order==null){

    
  }
} catch (error) {

}
}

const _getDays= async (item) => {
  
  setselectedMultiDay([])
  await axios.get(`https://getjob.stechomeyazilim.info:5101/getDaysMega/select/${item.ID}`)
  .then((res) => {
    if(res.data.length>0){

      res.data.map((item, index) => {
        setselectedMultiDay(oldArray => [...oldArray,{ ID:item.ID,label: item.Sheduled_DayTitle, value: item.ID,startTime : item.StartTime,finishTime : item.FinishTime }] )
        
      })
      
      setDaysModal(res.data)

    }

 
  })
}

  const postTeacherSession = async () => {

    axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteTeacherSession/${getCourseSchedulesID}`)

    if(getSelectTeacherList.length > 0){

    getSelectTeacherList.map(async(item, index) => {

      let jobSeekerExpreince = {
        Course_Schedules_ID: getCourseSchedulesID,
        Users_ID:item.value,
        IsActive:true
      }
      axios.post('https://getjob.stechomeyazilim.info:5101/postTeacherSessionMega/send', jobSeekerExpreince).then(async(res) => {
        toggleTeacher()
  
        setTimeout(() => {
          dispatch(ongetCourseSchedulesMega());
        }, 1000);

      
      
      })

  })    
}else{

  setTimeout(() => {
    dispatch(ongetCourseSchedulesMega());
  }, 1000);

  toggleTeacher()

  }
  
 
  
  }

  const _getWorkshopSession= async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getWorkshopSessionMega/select/`)
    .then((res) => {
      setWorkshopSessionData(res.data)
    })
  }

  const _getWorkshop = async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getWorkshopMega/select/`)
    .then((res) => {
      setWorkshopData(res.data)
    })
  }

  const _getLocation = async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getLocationMega/select/`)
    .then((res) => {
      setLocation(res.data)
    })
  }


  const _getDynamicForm = async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getDynamicFormMega/select/`)
    .then((res) => {
      setDynamicForm(res.data)
    })
  }
  
  const _getTeacherList = async () => {
    setTeacherList([])
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getTeacherMega/select/`)
    .then((res) => {
      res.data.map((item, index) => (

        setTeacherList(oldArray => [...oldArray,{label:item.NameSurname,value:item.ID}] )

      ))

    })
  }


  const _getUserList = async () => {
    setUserList([])
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserListMega/select/`)
    .then((res) => {
      res.data.map((item, index) => (

        setUserList(oldArray => [...oldArray,{label:item.NameSurname,value:item.ID}] )

      ))

    })
  }


  
  useEffect(() => {
    setNum(randomNumberInRange(1, 5));
  }, []);

  useEffect(() => {
    _getUserList()
  }, []);


  useEffect(() => {
    _getTeacherList()
  }, []);


  useEffect(() => {
    _getDynamicForm()
 
  }, []);

  useEffect(() => {
    _getWorkshop()
   
  }, []);
  useEffect(() => {
    _getWorkshopSession()
 
  }, []);
  useEffect(() => {
   
    if (orders && !orders.length) {
      dispatch(ongetCourseSchedulesMega());
      setIsEdit(false);
    }
  }, [dispatch, orders]);


  
  useEffect(() => {
    _getLocation();
  }, []);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggleClass = () => {
    if (getIsClassVisible) {
      setIsClassVisible(false);
     // setOrder(null);
    } else {
      setIsClassVisible(true);
    }
  };

  
  const toggleDaysModalVisible = () => {
    if (getIsDaysVisible) {
      setIsDaysVisible(false);
     // setOrder(null);
    } else {
      setIsDaysVisible(true);
    }
  };

  const toggleTeacher = () => {
    if (modalTeacher) {
      setModalTeacher(false);
     // setOrder(null);
    } else {
      setModalTeacher(true);
    }
  };


    const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };
  

  const _setChoiceDynamic = arg => {
    setChoiceDynamic(arg.target.value)
   }

  const _setChoiceLocation = arg => {
    setLocationID(arg.target.value)
    setLocationWorkshop(arg.target.value)
   }


  const setChoiceWorkshop = arg => {
    setWorkshopID(arg.target.value)
   }
  const setChoice = arg => {
   setWsID(arg.target.value)
  }


  const handleOpenDays = (arg,order) => {
    setOrder(order)
    setDaysModal([arg])
  
    _getDays(order)
    toggleDaysModalVisible();

  }

  
  const handleOpenClass = (arg,capacity,item) => {
    const order = arg;

    setSelectedmessageData(item)
    _selectedUser([])

   order.map(async(item, index) => {
      setClass(oldArray => [...oldArray,{ID: item.ID, Classes:item.Classes,label:item.Users.NameSurname,value:item.Users.ID}] )
   }) 
    setIsClassVisible(true);
    setClassCapacity(capacity);

    toggleClass();
  };

  const handleOpenTeacher = arg => {
    const order = arg;
    setWorkshopSessionEditList(order)
    setOrder(order);
  
    setCourseSchedulesID(order.Course_Schedules_ID)

    setIsEditTeacher(true);


   if(order.Course_Schedules.Teacher_Session.length>0){

    order.Course_Schedules.Teacher_Session.map(async(item, index) => {
      setSelectTeacherList(oldArray => [...oldArray,{label:item.Users.NameSurname,value:item.Users.ID}] )
   })
  }

    toggleTeacher();
  };

  const handleOrderClick = arg => {

    const order = arg;
    setWorkshopSessionEditList(order)
    setOrder(order);

   /* if(order.Course_Schedules.Workshop_Sessions != null){
    }
    if(order.Course_Schedules.Workshop != null){
    }
    if(order.Course_Schedules != null){

    }*/

    setWsText(order.Course_Schedules.Workshop_Sessions.Workshop_Sessions_Text)
    setWorkshopText(order.Course_Schedules.Workshop.Workshop_Text)
    setWsID(order.Course_Schedules.Workshop_Session_ID)
    setWorkshopID(order.Course_Schedules.Workshop_ID)

    setDays(order)

    setChoiceDynamic(order.Course_Schedules.Dynamic_Form_ID)
    setLocationID(order.Course_Schedules.Location_Workshop_ID)
    setWorksDay(order)
    setIsEdit(true);

    setIsActivePrice(order.Course_Schedules.IsMePayment)
    setIsActive(order.Course_Schedules.Is_Active)
    
   // setWorkshopText(order.Course_Schedules.Location_Workshop.Location_Workshop_Title)

    setLocationWorkshop(order.Course_Schedules.Location_Workshop.ID)

      setselectedMultiDay([{ ID:order.ID,label: order.Sheduled_DayTitle, value: order.ID,startTime : order.StartTime,finishTime : order.FinishTime}])

   
    toggle()
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.ID) {
      dispatch(onDeleteCourseSchedules(order.ID));
      setDeleteModal(false);
    }
  };



  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };
  

  

  const toggleModal = ()  => {
    setModal1(!modal1);
}

  const toggleViewModal = (item)  => {

    setTotalExtraUser(item.Number_OfQuotas_Extra)
   // setTeacherSessionExtraUser(item.Teacher_Session)

    {item.Teacher_Session.map((item, index) => (
      setTeacherSessionExtraUser(oldArray => [{ ID:item.ID,label: item.Users.NameSurname, value: item.Users.ID },...oldArray])
    ))}
    
    setModal1(!modal1)
    
}

  const columns = useMemo(
    () => [


      {
        Header: 'Başvuru Dönemi',
        accessor: 'Course_Schedules.Workshop_Sessions.Workshop_Sessions_Text',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <OrderId {...cellProps} />;
        }
      },
      {
        Header: 'Atölye Adı',
        accessor: 'Course_Schedules.Workshop.Workshop_Text',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'Form',
        accessor: 'Course_Schedules.Dynamic_Form.Dynamic_Form_Name',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'Tesis',
        accessor: 'Course_Schedules.Location_Workshop.Location_Workshop_Title',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'Gün',
        accessor: 'Sheduled_DayTitle',
        filterable: true,
        Cell: (cellProps) => {
          const item = cellProps.row.original
          return  ( 
           
            <Link
            onClick={() => {
              const orderData = cellProps.row.original;
              handleOpenDays(orderData,cellProps.row.original);
            }}
          className="text-success text-center"
        >
       {item.Sheduled_DayTitle} /  {item.StartTime} -  {item.FinishTime}
        </Link> 
        )
        }
      },
      {
        Header: 'Yaş',
        accessor: 'Course_Schedules.Age_Range',
        filterable: true,
        disableFilters:true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        }
      },
      {
        Header: 'Kontenjan',
        accessor: 'view3',
        filterable: true,
        disableFilters:true,
        Cell: (cellProps) => {
          let number =  cellProps.row.original.Course_Schedules != null ?  cellProps.row.original.Course_Schedules.Teacher_Session.length > 0 ?  cellProps.row.original.Course_Schedules.Teacher_Session[0] : null : null
          let NumberOfQuotas = cellProps.row.original.Course_Schedules != null ? cellProps.row.original.Course_Schedules.Number_OfQuotas - (number != null ? cellProps.row.original.Course_Schedules.Teacher_Session[0].Classes.length : 0) : null

          return( 
        
        <a
        className="text-danger text-center"
       
      >
   {NumberOfQuotas}
      </a>
        )
        }
      },
      {
        Header: 'Başvuran',
        accessor: 'view2',
        filterable: true,
        disableFilters:true,
        Cell: (cellProps) => {
          return( 
            cellProps.row.original.Course_Schedules != null ?  cellProps.row.original.Course_Schedules.Teacher_Session != null ?
            cellProps.row.original.Course_Schedules.Teacher_Session.length > 0 ? 
            <Link
          className="text-success text-center"
          onClick={() => {
            const orderData = cellProps.row.original.Course_Schedules.Teacher_Session;
            const item = cellProps.row.original;

            handleOpenClass(orderData,cellProps.row.original.Course_Schedules.Number_OfQuotas,item);
          }}
        >
       Sınıfları Listele
        </Link> : 
        <Link
        className="text-danger text-center"
        onClick={() => {
          const orderData = cellProps.row.original.Course_Schedules.Teacher_Session;
          handleOpenClass(orderData);
        }}
      >
    Önce Öğretmen Ata
      </Link>:null : null
        )
          
          }
      },
      {
        Header: 'Öğretmen',
        accessor: 'view1',
        filterable: true,
        disableFilters:true,
        Cell: (cellProps) => {
          return(
            
            cellProps.row.original.Course_Schedules != null ? cellProps.row.original.Course_Schedules.Teacher_Session != null ? 
            cellProps.row.original.Course_Schedules.Teacher_Session.length > 0 ? 
            cellProps.row.original.Course_Schedules.Teacher_Session.map((item, index) => (
              <Link
              key={index}
            className="text-success text-center"
            onClick={() => {
              const orderData = cellProps.row.original;
              handleOpenTeacher(orderData);
            }}>
              {item.Users.NameSurname}
           <a className="text-[red]"> Atandı</a>
          </Link>
           ))
            :
            <Link
            className="text-trancila text-center"
            onClick={() => {
              const orderData = cellProps.row.original;
              handleOpenTeacher(orderData);
            }}
          >
          Öğretmen Ata
          </Link> : null : null)
        }
      },
     
      {
        Header: 'Eks. Kontenjan',
        accessor: 'view',
        disableFilters: true,
        Cell: cellProps => {
          return (
            cellProps.row.original.Number_OfQuotas_Extra != null ? 
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={()=> toggleViewModal(cellProps.row.original)}
            >
          {cellProps.row.original.Number_OfQuotas_Extra}  Eks. Kontenjan
            </Button> : 
             <Button
             type="button"
             color="secondary"
             className="text-white"
             state={{ _item: cellProps.row.original }}
           >
          Eks.Konjentan Yok
           </Button>
            );
        }
      },

      {
        Header: 'Durum',
        accessor: 'viewstatus',
        disableFilters: true,
        Cell: cellProps => {
          return (
            cellProps.row.original.Course_Schedules.Is_Active != null ? 
            <Badge
            className={"font-size-12 badge-soft-" +
                (cellProps.row.original.Course_Schedules.Is_Active === true ? "success" : "danger" )}
        >
            {cellProps.row.original.Course_Schedules.Is_Active == true ? "Aktif" : "Pasif"}
        </Badge>
           : 
           null
            );
        }
      },

    

   
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
             {/*  <Link
                to="/mergeWorkshopSession"
                className="text-success text-center"
                state={{ _item: cellProps.row.original }}
              >
              Seansları Birleştir
              </Link>*/}

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );


  function handleMultiDay(selectedMulti) {
  //  console.log("lsdnflk",selectedMulti)
    setselectedMultiDay(selectedMulti);
  }


 const handleChange= async (val,type) => {
    if(type=="teacher"){
      setSelectTeacherList(val)
    }
    if(type == "user"){
      setSelectUserList(val)
    }
  }


  function randomNumberInRange(min, max) {
    //  get number between min and max
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const _handleTeacher= async (val) => {

   _selectedUser(val)
    _getClassesExtra(val.ID)
    }
  

    const _getClassesExtra= async (item) => {
  
      await axios.get(`https://getjob.stechomeyazilim.info:5101/searchClassesTeacherSession/select/${item}`)
      .then((res) => {
          setClassUser(res.data)
      })
    }

 
    const _setTime= async (time,day,type,ID) => {
    
      let getTimeFilter = getTime.filter((x)=> x.day == day)
      
      if (getTimeFilter.length  > 0){
        if(type == "finish"){
          let newMarkers = getTimeFilter.map(el => (
            el.day===day  ? {...el,
              ID:ID,startTime:el.startTime,finishTime:moment(time.toDate()).format('HH:mm'),day:day
            }: el
        ))
          setTime(newMarkers)
        }else{
          let newMarkers = getTimeFilter.map(el => (
            el.day===day  ? {...el,
              ID:ID,startTime:el.startTime,finishTime: el.finishTime,day:day
            }: el
        ))

          setTime(newMarkers)        
        }
      }else{
        if(type == "finish"){

      setTime(oldArray => [...oldArray,{ ID:ID,startTime:moment(time.toDate()).format('HH:mm'),finishTime:moment(time.toDate()).format('HH:mm'),day:day}] )
       
      }else{

        setTime(oldArray => [...oldArray,{ ID:ID,startTime:moment(time.toDate()).format('HH:mm'),finishTime:moment(time.toDate()).format('HH:mm'),day:day}] )

      }
      }
   

      }

    const handleTeacher= async (val) => {
          _selectedUser(val)
  
      }
      const dataUsers =  getClassUser.map(elt=> [
        elt.Users.NameSurname,
        elt.Users.Users_TCNumber,
        elt.Users.GsmNumber,
       // elt.Course_SheduledDay.length > 0 ? elt.Teacher_Session.Course_Schedules.Course_SheduledDay[0].Sheduled_DayTitle : null,
       // elt.Course_SheduledDay.length > 0 ?  elt.Teacher_Session.Course_Schedules.Course_SheduledDay[0].StartTime + " " +  elt.Course_Schedules.Course_SheduledDay[0].FinishTime : null,

              ]);

       const data =  orders.map((elt,index)=> [
                index + 1 ,
                elt.Course_Schedules != null ?  elt.Course_Schedules.Workshop != null ? elt.Course_Schedules.Workshop.Workshop_Text : null : null,
                elt.Course_Schedules != null ? elt.Course_Schedules.Workshop_Sessions != null ? elt.Course_Schedules.Workshop_Sessions.Workshop_Sessions_Text : null : null,
                elt.Sheduled_DayTitle,
                elt.StartTime + " " + elt.FinishTime,
                elt.Course_Schedules != null ? elt.Course_Schedules.Teacher_Session.length > 0 ?  elt.Course_Schedules.Teacher_Session[0].Users.NameSurname : null : null,
                      ]);
          
  return (
    <React.Fragment>
      <EcommerceOrdersModal handleTeacher={handleTeacher} _gelectedUser={_gelectedUser} _getTeacherSessionExtraUser={getTeacherSessionExtraUser} getTotalExtraUser={getTotalExtraUser} handleChange={handleChange} getUserList = {getUserList} getSelectUserList={getSelectUserList} isOpen={modal1} toggle={toggleViewModal} toggleModal={toggleModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="Atölye Seansları" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>

     <ExportToExcel apiData={data} fileName={fileName} />

                <Button
    type="button"
    color="danger"
    className="btn  mb-2 me-2"
    onClick={exportPDF}>
       <i className="mdi mdi-export me-1" />
                     PDF
                </Button>

                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isWorkshopSession={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Seansı Düzenle" : "Seans Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                  
                  <div className="d-flex">
                    <div className="mb-3">
                      <Label className="form-label">Kontenjan</Label>
                      <Input
                        name="numberOfQutas"
                        type="number"
                        placeholder="Konjentan"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.numberOfQutas || ""}
                        invalid={
                          validation.touched.numberOfQutas && validation.errors.numberOfQutas ? true : false
                        }
                      />
                      {validation.touched.numberOfQutas && validation.errors.numberOfQutas ? (
                        <FormFeedback type="invalid">{validation.errors.numberOfQutas}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Ek Kontenjan</Label>
                      <Input
                        name="numberOfQutasExtras"
                        type="number"
                        placeholder="Konjentan"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.numberOfQutasExtras}
                        invalid={
                          validation.touched.numberOfQutasExtras && validation.errors.numberOfQutasExtras ? true : false
                        }
                      />
                      {validation.touched.numberOfQutasExtras && validation.errors.numberOfQutasExtras ? (
                        <FormFeedback type="invalid">{validation.errors.numberOfQutasExtras}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Yaş Aralığı</Label>
                      <Input
                        name="ageRange"
                        type="text"
                        placeholder="Yaş Aralığı"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ageRange || ""}
                        invalid={
                          validation.touched.ageRange && validation.errors.ageRange ? true : false
                        }
                      />
                      {validation.touched.ageRange && validation.errors.ageRange ? (
                        <FormFeedback type="invalid">{validation.errors.ageRange}</FormFeedback>
                      ) : null}
                    </div>

                    </div>
                  
                    <div className="mb-3">
                      <Label className="form-label">Başvuru Dönemi Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoice(text)}
                        value={
                          getWsID
                        }>
                       <option value="">Lütfen Seçim Yapınız</option>

                        {getWorkshopSessionData.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Workshop_Sessions_Text}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>

                  
                    <div className="mb-3" style={{ zIndex: "1004" }}>
                      <Label className="form-label">Atölye Seçiniz</Label>
                      <Input
                        name="worhshopday"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoiceWorkshop(text)}
                        value={
                          getWorkshopID
                        }>
                                                           <option value="">Lütfen Seçim Yapınız</option>

                        {getWorkshopData.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Workshop_Text}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Tesis Seçiniz</Label>
                      <Input
                        name="location"
                        type="select"
                        className="form-select"
                        onChange={(text) => _setChoiceLocation(text)}
                        value={
                          getLocationWorkshop
                        }>
                                                           <option value="">Lütfen Seçim Yapınız</option>

                        {getLocation.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Location_Workshop_Title}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>


                    {getIsActiveForm == true ? <div className="mb-3">
                      <Label className="form-label">Dinamik Formu Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => _setChoiceDynamic(text)}
                        value={
                          getWsText
                        }>
                        {getDynamicForm.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Dynamic_Form_Name}</option>
                        ))}
                       
                      </Input>
                     

                    </div> : null}

                    <div className="d-flex">
                  
                    <div className="d-flex">
                    <Label className="control-label">Form Eklensin Mi?</Label>
                          <div className="square-switchForm">
                            <input
                              type="checkbox"
                              id="square-switchActiveForm"
                              className="switch"
                              defaultChecked={getIsActiveForm}
                              onChange={() =>
                                setIsActiveForm(!getIsActiveForm)
                              }
                            />
                            <label
                         htmlFor="square-switchActiveForm"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                        
                       
                        </div>

                    <div className="d-flex">
                    <Label className="control-label">Aktif Durumu</Label>
                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switchActive"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switchActive"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                        
                       
                        </div>

                        <div className="d-flex">
                    <Label className="control-label">Ücretli</Label>
                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switchPrice"
                              className="switch"
                              defaultChecked={getIsActivePrice}
                              onChange={() =>
                                setIsActivePrice(!getIsActivePrice)
                              }
                            />
                            <label
                         htmlFor="square-switchPrice"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                        
                       
                        </div>

                        {getIsActivePrice == true ?    <div className="d-flex">
                    <Label className="control-label">Ödeme Bünyemize</Label>
                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switchPriceMe"
                              className="switch"
                              defaultChecked={getIsMePayment}
                              onChange={() =>
                                setIsMePayment(!getIsMePayment)
                              }
                            />
                            <label
                         htmlFor="square-switchPriceMe"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                        
                       
                        </div> : null }
                        </div>

                  {getIsActivePrice == true ?  
                  <div className="mb-3">
                      <Label className="form-label">Ücret</Label>
                      <Input
                        name="price"
                        type="number"
                        placeholder="Ücret"
                       
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.price || ""}
                        
                      />
                      
                    </div> : null}

                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal isOpen={getIsClassVisible} toggle={toggleClass}>
            <ModalHeader toggle={toggleClass} tag="h4">
              {!!getIsClassVisible ? "Sınıflar" : "Sınıflar"}
            </ModalHeader>
            <ModalBody>


            <label className="form-label">
                         Toplam {getClassCapacity} Kontenjan
                          </label>


            <div className="mb-3" style={{ zIndex: "1005" }}>
                          <label className="form-label">
                           Öğretmen Seçiniz
                          </label>
                          <Select
                          placeholder={"Öğretmen Seçiniz"}
                            value={_gelectedUser}
                            isMulti={false}
                            onChange={(val) => {
                              _handleTeacher(val);
                            }}
                            options={getClass}
                            className="select2-selection"
                            styles={colourStyles}
                          />
                        </div>

                        {getClassUser.length > 0 ?
                        <ExportToExcel apiData={dataUsers} fileName={fileName} /> 
                        : null}


                      

      { _gelectedUser != null ? <div style={{ zIndex: "1001" }}>
        <MultiSelectOption handleChange={handleChange} fullData={getUserList} value={getSelectUserList} type={"user"}  />
        </div> : null}

        <div className="text-end">
                      <button
                      onClick={()=> _postUser()}
                        type="button"
                        className="btn btn-success save-user">
                        Ekle
                      </button>
                    </div>

           {getClassUser.length > 0 ?
          
          getClassUser.map((item, index) => {
           return(
            <div className="d-flex align-items-center" key={index}>
               <p
  key={index}
className="text-success text-center">
Öğrenci - {item.Users.NameSurname} 
</p>

<button
                      onClick={()=> _deleteClassUser(item.ID)}
                        type="button"
                        className="btn btn-danger save-user">
                        Sil
                      </button>

</div>

         )}) : 
        
          <div>
            <p
className="text-success text-center">
KAYITLI ÖĞRENCİ YOK</p>
</div> }
           
                <Row className="d-flex">
             
                  <Col>
                    <div className="text-end">
                      <button
                      onClick={()=> toggleClass()}
                        type="button"
                        className="btn btn-danger save-user">
                        Kapat
                      </button>
                    </div>
                  </Col>

                </Row>
              
              
              
            </ModalBody>
          </Modal>

          <Modal isOpen={getIsDaysVisible} toggle={toggleDaysModalVisible}>
            <ModalHeader toggle={toggleDaysModalVisible} tag="h4">
              {!!getIsDaysVisible ? "Günler" : "Günler"}
            </ModalHeader>
            <ModalBody>


           
            <div className="mb-3" style={{ zIndex: "1005" }}>
                          <label className="form-label">
                           Gün Seçiniz
                          </label>
                          <Select
                            value={selectedMultiDay}
                            isMulti={false}
                            onChange={(val) => {
                              handleMultiDay(val);
                            }}
                            options={optionDay}
                            className="select2-selection"
                            styles={colourStyles}
                            placeholder={"Gün Seçiniz"}
                          />
                        </div>

                     {   selectedMultiDay.length > 0 ?
                      <div>
                              <LocalizationProvider  adapterLocale="tr" dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['TimePicker']}>        
                                <TimePicker
                               
                          label={selectedMultiDay[0].label + " Günün Başlangıç Saati"} 
                          value={moment(selectedMultiDay[0].startTime, 'HH:mm:ss')}
                          onChange={(newValue) => _setTime(newValue,selectedMultiDay[0].label,'start',selectedMultiDay[0].ID)}
                        />
                        
                        
                        <TimePicker
                       
                          label={selectedMultiDay[0].label + " Günün Bitiş Saati"} 
                          value={moment(selectedMultiDay[0].finishTime, 'HH:mm:ss')}
                          onChange={(newValue) => _setTime(newValue,selectedMultiDay[0].label,'finish',selectedMultiDay[0].ID)}
                        />
                        
                        
                              </DemoContainer>
                            </LocalizationProvider>
                            </div> : 
                          <div>
                          <LocalizationProvider  adapterLocale="tr" dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['TimePicker']}>        
                            <TimePicker
                           
                      label={selectedMultiDay.label + " Günün Başlangıç Saati"} 
                      value={moment(selectedMultiDay.startTime, 'HH:mm:ss')}
                      onChange={(newValue) => _setTime(newValue,selectedMultiDay.label,'start',selectedMultiDay.ID)}
                    />
                    
                    
                    <TimePicker
                   
                      label={selectedMultiDay.label + " Günün Bitiş Saati"} 
                      value={moment(selectedMultiDay.finishTime, 'HH:mm:ss')}
                      onChange={(newValue) => _setTime(newValue,selectedMultiDay.label,'finish',selectedMultiDay.ID)}
                    />
                    
                    
                          </DemoContainer>
                        </LocalizationProvider>
                        </div>
                        }
                        
                       

              

           { getDaysModal.map((item, index) => (
              <p
              key={index}
            className="text-success text-center">
         {item.Sheduled_DayTitle} / {item.StartTime} - {item.FinishTime}
          </p>
           ))}
           
                <Row>
                  <Col className="col-12">
                  <div className="text-end">
                      <button
                      onClick={()=> postDay()}
                        type="button"
                        className="btn btn-success save-user">
                        Günleri Ekle
                      </button>
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                      onClick={()=> toggleDaysModalVisible()}
                        type="button"
                        className="btn btn-danger save-user">
                        Kapat
                      </button>
                    </div>
                  </Col>
                </Row>
              
            </ModalBody>
          </Modal>

          <Modal isOpen={modalTeacher} toggle={toggleTeacher}>
            <ModalHeader toggle={toggleTeacher} tag="h4">
              {!!getIsEditTeacher ? "Öğretmen Düzenle" : "Öğretmen Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                   <MultiSelectOption  handleChange={handleChange} fullData={getTeacherList} value={getSelectTeacherList} type={"teacher"}  />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                      onClick={()=> postTeacherSession()}
                        type="button"
                        className="btn btn-success save-user">
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;