import React , {useState} from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import {ExportToExcel} from '../../ExportToExcel'
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";

const EcommerceOrdersModal = props => {
  const { isOpen, toggle,getItemToggle } = props
  const [num, setNum] = useState(0);

  const fileName = "myfile" + num; // here enter filename for your excel file
  
  const [getFileChange, setFileChange] = useState([]);

  function changeFile(title,check,tcNumber) {
    console.log("tcNumber12321",tcNumber)

    let filterData = getFileChange.filter((x)=> x.value == title)

    if(filterData.length>0){
     if(check == true){
      let newMarkers = getFileChange.map(el => (
        el.value===title  ? {...el,
          value : title, 
          checked : check,
          usersTc : tcNumber
        }: el
    ))
    setFileChange(newMarkers)
     }else{
      const filteredData = getFileChange.filter(item => item.value !== itemData.title)
      setFileChange(filteredData)
     }
    
    }
    else{

      if(check == false){
        const filteredData = getFileChange.filter(item => item.value !== itemData.title)
        setFileChange(filteredData)
      }else{

        setFileChange(oldArray => [...oldArray,{value: title, checked : check,usersTc : tcNumber }] )
      }
    }
   
  }

  const exportAllFile = () => {
      
  getItemToggle.DynamicForm_Success.map(async(item, index) => {
   if(item.Dynamic_Form_Extra.DynamicType == "Dosya Yükleme Alanı"){
    try {
      const response = await axios.get(`https://getjob.stechomeyazilim.info:5101/download-image?url=https://megasubdomain.stechomeyazilim.info/${item.DynamicForm_Success_Value}`);
    
      const a = document.createElement('a');
      a.href = `data:image/png;base64,${response.data.base64Image}`;
   
      a.download = item.DynamicForm_Success_DataType == "image/heic" ? item.Users.NameSurname + "_" + item.Users.Users_TCNumber + '.png' : item.DynamicForm_Success_DataType == "image/jpeg" ? item.Users.NameSurname + "_" + item.Users.Users_TCNumber + '.png' :  item.DynamicForm_Success_DataType == "application/pdf" ?  item.Users.NameSurname + "_" + item.Users.Users_TCNumber + '.pdf' : item.DynamicForm_Success_DataType == "image/png" ?  item.Users.NameSurname + "_"  + item.Users.Users_TCNumber + '.png' : item.DynamicForm_Success_DataType == "image/jpeg" ? item.Users.NameSurname + "_" +  item.Users.Users_TCNumber + '.png' :null;
      a.click();

     // setBase64Photo(response.data.base64Image);

    } catch (error) {
      console.error('Hata:', error);
      return null;
    }
   }
  })

  }
  const exportPDF = () => {

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Öğrenci Form Sonuçları";
    const headers = [["Soru", "Cevap", "Veri Tipi"]];

    const data = getItemToggle != null ? getItemToggle.DynamicForm_Success.map(elt=> [elt.Dynamic_Form_Extra.Title, elt.DynamicForm_Success_Value, elt.Dynamic_Form_Extra.DynamicType, elt.Users.Users_TCNumber, elt.Users.GsmNumber, elt.Users.NameSurname]) : null;

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(getItemToggle.Users.Users_TCNumber + "report.pdf")
  }

  
  const dataToggle = getItemToggle != null ? getItemToggle.DynamicForm_Success.map(elt=> [elt.Dynamic_Form_Extra.Title, elt.DynamicForm_Success_Value, elt.Dynamic_Form_Extra.DynamicType, elt.Users.Users_TCNumber, elt.Users.GsmNumber, elt.Users.NameSurname]) : null;

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      size="xl"
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Form Detayı</ModalHeader>
        <ModalBody>
        {getItemToggle != null ?
        <ExportToExcel apiData={dataToggle} fileName={getItemToggle.Users.NameSurname + getItemToggle.Users.Users_TCNumber} />
          : null }

<Button
    type="button"
    color="danger"
    className="btn  mb-2 me-2"
    onClick={exportPDF}>
       <i className="mdi mdi-export me-1" />
                     PDF
                </Button>

                <Button
    type="button"
    color="success"
    className="btn  mb-2 me-2"
    onClick={exportAllFile}>
       <i className="mdi mdi-export me-1" />
                     Dosyaları İndir
                </Button>

          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                      <tr>
        
                  <th scope="col">Soru</th>
                  <th scope="col">Cevap</th>
                  <th scope="col">Tipi</th>

                </tr>
               
              </thead>
              <tbody>
                <tr>
                <th scope="row">
                  

                  {getItemToggle != null ? getItemToggle.DynamicForm_Success.map((item, index) => {
                      return(
                      <div key={index}>
                                    {item.Dynamic_Form_Extra.Title}
                                      </div>
                                      )
                  }) : null}
  
                                        </th>
                                        
                  <th scope="row">
                  {getItemToggle != null ? getItemToggle.DynamicForm_Success.map((item, index) => {
                    return(
                    <div key={index}>
                                  {item.DynamicForm_Success_Value}
                                    </div>
                                    )
                }) : null}

               
                    
                  </th>
                 
                  <th scope="row">
                  {getItemToggle != null ? 
                  getItemToggle.DynamicForm_Success.map((item, index) => {
                  let filterData = getFileChange.filter((x)=> x.value == item.ID)
                    return(
                    <div key={index}>
                     {item.Dynamic_Form_Extra.DynamicType}
                                  
                                    </div>
                                    )
                                  })
                 : null}

                  </th>

                
                  
                </tr>
               
                
                 
                 
               
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Kapat
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EcommerceOrdersModal
