import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { filter, isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import  moment from "moment";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import ConfirmModal from '../../../components/Common/ConfirmModal';
import DeleteModal from '../../../components/Common/DeleteModal';


import {
  addNewWorkshop2 as onAdddNewWorkshop2,
  getClassAttendance as onGetClassAttendance,
  updateWorkshop2 as onUpdateWorkshop2,
  deleteClassAttanance as onDeleteClassAttanance,

} from "store/contacts/actions";

import {
  Title,
  Date,
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";
import axios from 'axios';

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";


function EcommerceOrder() {

  //meta title
  document.title = "DEVAMSIZLIK | MEGA - Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getWorkShopEdit, setWorkShopEdit] = useState(null);
 


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ID: (getWorkShopEdit && getWorkShopEdit.ID) || '',

           title: (getWorkShopEdit && getWorkShopEdit.Workshop_Text) || '',

    },
    validationSchema: Yup.object({
      title: Yup.string().required("Başlığı Giriniz"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateWorkshop = {
          ID: order ? order.ID : 0,
          Workshop_Text: values.title,
          Workshop_IsActive:true
        //  Workshop_StartDate: values.orderdate,
        //   Workshop_FinishDate: values.startDate,
        };
        // update order
        dispatch(onUpdateWorkshop2(updateWorkshop));
        validation.resetForm();
      } else {
        const newOrder = {
          Workshop_Text: values["title"],
          Workshop_IsActive:true
        };
        // save new order
        dispatch(onAdddNewWorkshop2(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.classattendance,
  }));


  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const [getConfirmModal, setConfirmModal] = useState(false);



  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.ID) {
      dispatch(onDeleteClassAttanance(order.ID));
      setDeleteModal(false);    
    }
  };

  const handleConfirm = () => {
    setConfirmModal(true);
  }

  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Öğrenci Ad Soyad',
        accessor: 'Users.NameSurname',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
      {
        Header: 'Dönem',
        accessor: 'Teacher_Session.Course_Schedules.Workshop_Sessions.Workshop_Sessions_Text',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
   
      {
        Header: 'Atölye Adı',
        accessor: 'Teacher_Session.Course_Schedules.Workshop.Workshop_Text',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
      {
        Header: 'Gelmediği Gün',
        accessor: 'Created_DateTime',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },

    /* {
        Header: 'Detaylar',
        accessor: 'view',
        disableFilters: true,
        Cell: () => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={toggleViewModal}
            >
              Detayları Görüntüle
            </Button>);
        }
      },*/
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
            
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

   
  const [getCourseSchedules, setCourseSchedules] = useState([]);
  const [getStudent, setStudent] = useState([]);
  const [getStudentExtra, setStudentExtra] = useState([]);

  const [getCourseSchedulesID, setCourseSchedulesID] = useState(null);
  const [getWsText, setWsText] = useState(null);

  const _getCourseSchedules = async () => {
  
    let getData =  JSON.parse(localStorage.getItem("authUser"));

    await axios.get(`https://getjob.stechomeyazilim.info:5101/searchTeacherWorkshopMega/select/${getData.ID}`)
    .then((res) => {
      setCourseSchedules(res.data)

      if(res.data.length == 1){
        setCourseSchedulesID(res.data[0].ID)

        _getClasses(res.data[0].ID)
        dispatch(onGetClassAttendance(res.data[0].ID));
       }

    })
  }

  useEffect(() => {
    _getCourseSchedules()
  }, []);

  const _getClasses = async (ID) => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/searchClassesMega1/select/${ID}`)
    .then((res) => {
      setStudent(res.data)
    })

    await axios.get(`https://getjob.stechomeyazilim.info:5101/searchClassesExtraMega/select/${ID}`)
    .then((res) => {

      console.log("ID1cc2321",res.data)

      setStudentExtra(res.data)
    })

  }

  const postClassAttendance = async (ID) => {
    var localDate = moment().local();
    setConfirmModal(false);

    console.log("getCourseccSchedulesID123",getCourseSchedulesID,getAttStudentID)
    getAttStudentID.map((item, index) => {
    let jobSeekerExpreince = {
      Teacher_Session_ID: getCourseSchedulesID,
      Created_DateTime:localDate,
      Users_ID:item.ID
    }
    axios.post('https://getjob.stechomeyazilim.info:5101/postClassAttendanceMega/send', jobSeekerExpreince)

  })



  const delay = 1000; // 2 saniye bekleyecek
  setTimeout(() => {
    // Burada çağırmak istediğiniz fonksiyonu yazabilirsiniz
    //console.log('Fonksiyon çağrıldı!');
    dispatch(onGetClassAttendance(getCourseSchedulesID));
  }, delay);
  
   
  }

  const [getAttStudentID, setAttStudentID] = useState([]);

  const setCheckStudentExtra= arg => {
    console.log(arg.target.value)
    console.log(arg.target)

    const filteredData = getAttStudentID.filter((x)=> x.ID == arg.target.value)

   if(filteredData.length > 0){
    setAttStudentID(filteredData)
   }else{
    setAttStudentID(oldArray => [...oldArray,{ID:arg.target.value}] )
   }
   
     }

  const setCheckStudent = arg => {
    console.log(arg.target.value)
    console.log(arg.target)

    const filteredData = getAttStudentID.filter((x)=> x.ID == arg.target.value)

   if(filteredData.length > 0){
    setAttStudentID(filteredData)
   }else{
    setAttStudentID(oldArray => [...oldArray,{ID:arg.target.value}] )
   }
   
     }
     


  const setChoice = arg => {
    setCourseSchedulesID(arg.target.value)
    _getClasses(arg.target.value)
    dispatch(onGetClassAttendance(arg.target.value));

   }

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ConfirmModal
        show={getConfirmModal}
        onHandleConfirm={postClassAttendance}
        onCloseClick={() => setConfirmModal(false)}
        getAttStudentID={getAttStudentID}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Öğretmen" breadcrumbItem="Devamsızlık" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                 
                <div className="mb-3">
                      <Label className="form-label">Dersi Seçiniz</Label>
                      
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoice(text)}
                        value={
                          getWsText
                        }>

                        {getCourseSchedules.map((item, index) => (
                           <option  value={item.ID} key={item.ID}>{item.Course_Schedules.Workshop_Sessions.Workshop_Sessions_Text + " " + item.Course_Schedules.Workshop.Workshop_Text + " / Toplam " + item.Course_Schedules.Course_SheduledDay.length + " Gün /" + item.Course_Schedules.Age_Range + " Yaş Aralığı"}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>
                    
                  
                  
                    {getStudent.map((item, index) => (
                      <div key={index}>
                         <label
                      
                      className="form-check-label"
                      htmlFor="defaultCheck2"
                    >
                     Sınıf Listesi
                    </label>
                     
                        <div  key={index} className="form-check form-check-end">
                           

                       <Input
                       className="form-check-input"
                       type="checkbox"
                       value={item.Users.ID}
                    
                       id="defaultCheck2"
                       onChange={(text) => setCheckStudent(text)}
                     />
                     <label
                       value={item.Users.ID}
                        key={item.ID}
                       className="form-check-label"
                       htmlFor="defaultCheck2"
                     >
                      {item.Users.NameSurname}
                     </label>
                     </div>
                     </div>
                        ))}
                  
                 {getStudentExtra.map((item, index) => (
                  <div  key={index}>
                     <label
                      
                      className="form-check-label"
                      htmlFor="defaultCheck2"
                    >
                     Ek Kontenjan Listesi
                    </label>
                
                        <div  key={index} className="d-flex form-check form-check-end">
                           
                       <Input
                       className="form-check-input"
                       type="checkbox"
                       value={item.Users.ID}
                    
                       id="defaultCheck2"
                       onChange={(text) => setCheckStudentExtra(text)}
                     />
                     <label
                       value={item.Users.ID}
                        key={item.ID}
                       className="form-check-label"
                       htmlFor="defaultCheck2"
                     >
                      {item.Users.NameSurname}
                     </label>
                     </div>
                     </div>
                        ))}

              
{getStudent.length > 0 || getStudentExtra.length > 0 ?  <input
                      className="btn btn-info"
                      type="button"
                      value="Yoklama Al"
                      onClick={()=> handleConfirm()}
                    /> : null}
                        
                        
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={false}
                    isAddWorkshop2={false}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Atölye Düzenle" : "Atölye Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">

                    <div className="mb-3">
                      <Label className="form-label">Başlangıç Zamanı</Label>
                      <Input
                        name="startdate"
                        type="date"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.startDate || ""}
                        invalid={
                          validation.touched.startDate && validation.errors.startDate ? true : false
                        }
                      />
                      {validation.touched.startDate && validation.errors.startDate ? (
                        <FormFeedback type="invalid">{validation.errors.startDate}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Bitiş Zamanı</Label>
                      <Input
                        name="finishdate"
                        type="date"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.finishDate || ""}
                        invalid={
                          validation.touched.finishDate && validation.errors.finishDate ? true : false
                        }
                      />
                      {validation.touched.finishDate && validation.errors.finishDate ? (
                        <FormFeedback type="invalid">{validation.errors.finishDate}</FormFeedback>
                      ) : null}
                    </div>
                  
                    <div className="mb-3">
                      <Label className="form-label">Başlık</Label>
                      <Input
                        name="title"
                        type="text"
                        placeholder="Başlık"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title ? true : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                      ) : null}
                    </div>
                  
                  
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        KAYDET
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;