import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GETTSDASHBOARD,GET_USERDYNAMICFORM,DELETE_CLASSATTANCE,DELETE_LOCATION,GET_ALLWORKSHOPMEGA,GET_TEACHERSESSIONMEGA,DELETE_LESSONSTIME,DELETE_WORKSHOPSESSION,ADD_DYNAMIC_FORM3, DELETE_SUBPROJECT,DELETE_DYNAMICFORM,DELETE_DYNAMICFORMEXTRA,DELETE_COURSESCHEDULES,DELETE_GALLERY,DELETE_NEWS,GET_DYNAMICFORM_EXTRA_EXTRA,GET_DYNAMICFORM_EXTRA,UPDATE_DYNAMICFORM,GET_CLASSES,GET_CLASSATTENDANCE,UPDATE_LESSONS_TIME,GET_TEACHER_WORKSHOP,GET_SUBPROJECT,UPDATE_REFERANCES,ADD_REFERANCES,GET_REFERANCES,ADD_WORKSHOP2,ADD_APPSETTING,ADD_WORKSHOPSUBDOMAIN,UPDATE_GALLERY,GET_GALLERY,UPDATE_WORKSHOP2,UPDATE_MEGA,UPDATE_AUTH,GET_AUTHORITY,UPDATE_CONTACT,GET_CONTACT,UPDATE_WORKSHOPSESSION,UPDATE_SUBDOMAIN,ADD_COURSE,ADD_MEGA,GET_MEGA,GET_NEWS,GET_PROJECTSNEW,GET_SLIDER,GET_DYNAMICFORM,GET_EVENT,GET_TeacherMega,GET_WorkshopSessionMega,GET_CourseSchedulesMega,GET_POPUP,GET_USERS, GET_USER_PROFILE , ADD_NEW_USER , DELETE_USER, UPDATE_USER, GET_WORKSHOP, ADD_POPUP, ADD_SLIDER, ADD_PROJECT, ADD_NEWS, UPDATE_NEWS, UPDATE_TEACHER, UPDATE_COURSESCHEDULES, UPDATE_SLIDER, UPDATE_PROJECT, GET_WORKSHOP2, ADD_LESSONS_TIME, ADD_DYNAMIC_FORM, DELETE_MEGA, DELETE_SLIDER, DELETE_POPUP, DELETE_REFERANCES, ADD_GALLERY, ADD_GALLERYMULTIPLE, DELETE_DYNAMICFORMEXTRAEXTRA, DELETE_SUBDOMAIN, ADD_NEWTEACHER, DELETE_TEACHER, ADD_SUBPROJECT, UPDATE_SUBPROJECT, ADD_AUTH, UPDATE_POPUP, DELETE_USERTYPE, ADD_DYNAMIC_FORM2, DELETE_WORKSHOP2, ADD_TEACHERWORKSHOP, GET_LOCATION, ADD_LOCATION, UPDATE_LOCATION, UPDATE_DYNAMICFORM2, DELETE_DYNAMICFORMUSER, UPDATE_FORMSUCCESSUSER, GET_ALLDYNAMICFORM, GET_USERDYNAMICFORM_NULL, UPDATE_FORMSUCCESSUSER_NULL, GET_USERNULL, GET_USERCLASS, GET_USERPAYMENT, DELETE_PROJECT } from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getPopupSuccess,
  getWorkshopSuccess,
  getWorkshopSessionSuccess,
  getApplicationSuccess,
  getSliderSuccess,
  getProjectSuccess,
  getNewsSuccess,
  getMegaSuccess,
  getTeacherSuccess,
  getDynamicSuccess,

  
  getAuthSuccess,
  getAuthUpdateSuccess,
  updateSubdomainSuccess,
  updateSuccessCourseSchedules,
  updateWorkShopSessionSuccess,
  updateTeacherSuccess,
  updateSliderSuccess,
  updateSuccessProject,
  updateNewsSuccess,
  updateMegaSuccess,
  getWorkshop2Success,
  updateWorkShop2Success,
  getGallerySuccess,
  updateGallerySuccess,
  getReferancesSuccess,

  addSuccessReferances,
  updateSuccessReferances,
  getSubProjectSuccess,
  getTeacherWorkshopSuccess,
  addSuccessLessonsTime,
  updateLessonsTimeSuccess,
  getClassAttendanceSuccess,
  getClassesSuccess,
  updateDynamicFormSuccess,
  addDynamicFormSuccess,
  getDynamicFormExtraSuccess,
  getDynamicFormExtraExtraSuccess,

  addMegaSuccess,
  deleteMegaSuccess,

  deleteNewsSuccess,
  deleteSliderSuccess,

  deletePopupSuccess,
  deleteReferancesSuccess,
  addGallerySuccess,
  deleteGallerySuccess,
  deleteCourseSchedulesSuccess,
  deleteDynamicFormSuccess,
  deleteDynamicExtraFormSuccess,

  deleteDynamicExtraExtraFormSuccess,
  deleteSubDomainSuccess,
  addSubdomainSuccess,
  addNewTeacherSuccess,
  deleteTeacherSuccess,
  deleteSubProjectSuccess,
  addSubProjectSuccess,
  updateSubProjectSuccess,
  addNewAuthSuccess,
  updatePopupSuccess,
  deleteUserTypeSuccess,
  addDynamicFormSuccess2,
  addDynamicForm3Success,
  addAppSettingSuccess,
  deleteWorkshopSessionSuccess,
  addWorkshop2Success,
  deleteWorkshop2Success,
  addCourseMegaSuccess,
  deleteLessonsTimeSuccess,
  addSliderSuccess,
  addTeacherWorkshopSuccess,
  getTeacherSessionMegaSuccess,
  getAllWorkshopMegaSuccess,
  getLocationFormSuccess,
  addLocationSuccess,
  updateLocationSuccess,
  deleteLocationSuccess,
  deleteClassAttananceSuccess,
  getUserDynamicFormSuccess,
  updateynamicForm2Success,
  deleteDynamicFormSuccessUserSuccess,
  updateFormSuccessUserSuccess,
  getAllDynamicFormSuccess,
  getUserDynamicFormNullSuccess,
  updateFormSuccessUserNullSuccess,
  getUserNullSuccess,
  getUserClassSuccess,
  getTSDashboardSuccess,
  getUserPaymentSuccess,
  addProjectSuccess1,
  deleteProjectSuccess1,
  addPopupSuccess,
  updateCourseSchedulesSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { deleteProject1,getUserPayment,getTeacherSessionDashBoard,getUserClass,getUserNull,getDynamicFormNull,getAllDynamicForm,updateFormSuccessUser,deleteDynamicFormUser,updateDynamicFormExtra,getUserDynamicForm,deleteClassAttance,deleteLocation,updateLocation,addLocationMega,getLocation,getTeacherAllWorkshop, getTeacherSessionMega,addTeacherWorkshop,deleteTeacherWorkshop,deleteWokshop2,deleteWorkshopSession,addDynamicForm3,addDynamicForm2,deleteUserType,updatePopup,addAuth,updateSubProject,addSubProject,deleteSubProject,deleteTeacher,addTeacher,deleteSubDomain,deleteDynamicFormExtraExtra,deleteDynamicForm,deleteDynamicFormExtra,deleteCourseSchedules,addMultipleGalleryForm,deleteGallery,addGallerry,deleteReferances,deletePopup,deleteSlider,deleteNews,deleteMega,getDynamicFormExtraExtraMega,getDynamicFormExtraMega,addDynamicForm,updateDynamicForm,getClassesMega,getClassAttendance,updateLessonsTime,addLessonsTime,getTeacherWorkshop,getSubProject,updateReferances,addReferances,getReferances,addWorkshop2,addAppSetting,addNewSubdomain,updateGallery,getGallery,updateWorkShop2,getWorkshop2,updateMega,updateProject,updateSlider,updateAuth,getAuthority,updateContact,getContact,updateTeacher,updateWorkshopSession,updateSubdomain,addCourseMega,updateCourseSchedules,updateNews,addMega,addNews,addProject,addSlider,addPopup,getMegaMega,getNews,getProjectsNew,getSlider,getDynamicFormMega,getEventMega,getTeacherMega,getWorkshopSessionMega,getPopup,getUsers, getUserProfile , addNewUser, updateUser ,deleteUser ,getWorkShop,getCourseSchedulesMega} from "../../helpers/fakebackend_helper"



function* onGetUserClass(item) {
  try {
    const response = yield call(getUserClass)
    yield put(getUserClassSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetUserNull(item) {
  try {
    const response = yield call(getUserNull)
    yield put(getUserNullSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetUserDynamicFormNull(item) {
  try {
    const response = yield call(getDynamicFormNull)
    yield put(getUserDynamicFormNullSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onUpdateFormSuccessUserNull({ payload: user }) {
  try {
    console.log("jnsdlfburada")

    const response = yield call(updateFormSuccessUser, user)
    console.log("jnsdlf",response.value[0])
    yield put(updateFormSuccessUserNullSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateFormSuccessUser({ payload: user }) {
  try {
    const response = yield call(updateFormSuccessUser, user)
    yield put(updateFormSuccessUserSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateDynamicFormExtra({ payload: user }) {
  try {
    const response = yield call(updateDynamicFormExtra, user)
    yield put(updateynamicForm2Success(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetAllDynamicForm(item) {
  try {
    const response = yield call(getAllDynamicForm)
    yield put(getAllDynamicFormSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetUserDynamicForm(item) {
  try {
    const response = yield call(getUserDynamicForm)
    yield put(getUserDynamicFormSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdatePopup({ payload: user }) {
  try {
    const response = yield call(updatePopup, user)
    yield put(updatePopupSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateDynamicForm({ payload: user }) {
  try {
    const response = yield call(updateDynamicForm, user)
    yield put(updateDynamicFormSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}



function* onGetTeacherSessionDashBoard() {
  try {
    const response = yield call(getTeacherSessionDashBoard)
    yield put(getTSDashboardSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetTeacherSessionMega(item) {
  try {
    const response = yield call(getTeacherSessionMega, item.users)
    yield put(getTeacherSessionMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onGetDynamicFormExtraExtra(item) {
  try {
    const response = yield call(getDynamicFormExtraExtraMega, item.users)
    yield put(getDynamicFormExtraExtraSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetDynamicFormExtra(item) {
  try {
    const response = yield call(getDynamicFormExtraMega, item.users)
    yield put(getDynamicFormExtraSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetClasses() {
  try {
    const response = yield call(getClassesMega)
    yield put(getClassesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetClassAttendance(item) {
  try {
    console.log("lsndflk",item)
    const response = yield call(getClassAttendance, item.users)
    yield put(getClassAttendanceSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetLocationMega() {
  try {
    const response = yield call(getLocation)
    yield put(getLocationFormSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetTeacherWorkshopMega(item) {
  try {
    console.log("lnskldf",item)
    const response = yield call(getTeacherAllWorkshop, item.news)
    yield put(getTeacherWorkshopSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetAllWorkshopMega() {
  try {
    const response = yield call(getTeacherAllWorkshop)
    yield put(getAllWorkshopMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetSubProject(item) {
  try {
    const response = yield call(getSubProject, item.news)
    yield put(getSubProjectSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateLessonsTime({ payload: user }) {
  try {
    const response = yield call(updateLessonsTime, user)

    console.log("lksdnfkl",response.value[0])
    yield put(updateLessonsTimeSuccess(response.value[0],user))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateLocation({ payload: user }) {
  try {
    const response = yield call(updateLocation, user)

    console.log("lksdnfkl",response.value[0])
    yield put(updateLocationSuccess(response.value[0],user))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onAddLocation({ payload: user }) {

  try {
    const response = yield call(addLocationMega, user)

    yield put(addLocationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddLessonsTime({ payload: user }) {

  try {
    const response = yield call(addLessonsTime, user)

    yield put(addSuccessLessonsTime(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddReferances({ payload: user }) {

  try {
    const response = yield call(addReferances, user)

    yield put(addSuccessReferances(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onGetReferances() {
  try {
    const response = yield call(getReferances)
    yield put(getReferancesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchGallery() {
  try {
    const response = yield call(getGallery)
    yield put(getGallerySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchAuthority() {
  try {
    const response = yield call(getAuthority)
    yield put(getAuthSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchContact() {
  try {
    const response = yield call(getContact)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateAuth({ payload: user }) {
  try {
    const response = yield call(updateAuth, user)
    yield put(getAuthUpdateSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateReferances({ payload: user }) {
  try {
    const response = yield call(updateReferances, user)
    yield put(updateSuccessReferances(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateProject({ payload: user }) {
  try {
    const response = yield call(updateProject, user)
    yield put(updateSuccessProject(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateContact({ payload: user }) {
  try {
    const response = yield call(updateContact, user)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateTeacher({ payload: user }) {
  try {
    const response = yield call(updateTeacher, user)
    yield put(updateTeacherSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateWorkshopSession({ payload: user }) {
  try {
    const response = yield call(updateWorkshopSession, user)
    yield put(updateWorkShopSessionSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateSlider({ payload: user }) {
  try {
    const response = yield call(updateSlider, user)
    yield put(updateSliderSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateSubdomain({ payload: user }) {
  try {
    const response = yield call(updateSubdomain, user)
    yield put(updateSubdomainSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* fetchMega() {
  try {
    const response = yield call(getMegaMega)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchNews() {
  try {
    const response = yield call(getNews)
    yield put(getNewsSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchProjects() {
  try {
    const response = yield call(getProjectsNew)
    yield put(getProjectSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchSlider() {
  try {
    const response = yield call(getSlider)
    yield put(getSliderSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetcDynamicFormMega() {
  try {
    const response = yield call(getDynamicFormMega)
    yield put(getDynamicSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetcEventMega() {
  try {
    const response = yield call(getEventMega)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetcTeacherMega() {
  try {
    const response = yield call(getTeacherMega)
    yield put(getTeacherSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetUserPayment() {
  try {
    const response = yield call(getUserPayment)
    yield put(getUserPaymentSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshopSessionMega() {
  try {
    const response = yield call(getWorkshopSessionMega)
    yield put(getApplicationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* fetchCourseSchedulesMega() {
  try {
    const response = yield call(getCourseSchedulesMega)
    yield put(getWorkshopSessionSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshop2() {
  try {
    const response = yield call(getWorkshop2)
    yield put(getWorkshop2Success(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchPopup() {
  try {
    const response = yield call(getPopup)
    yield put(getPopupSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshop() {
  try {
    const response = yield call(getWorkShop)
    yield put(getWorkshopSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(getUserProfile)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* onUpdateCourseSchedules({ payload: user }) {
  try {
    const response = yield call(updateCourseSchedules, user)
    yield put(updateCourseSchedulesSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateNews({ payload: user }) {
  try {
    const response = yield call(updateNews, user)
    yield put(updateNewsSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateGalllery({ payload: user }) {
  try {
    const response = yield call(updateGallery, user)
    yield put(updateGallerySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateWorkshop2({ payload: user }) {
  try {
    const response = yield call(updateWorkShop2, user)
    yield put(updateWorkShop2Success(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateMega({ payload: user }) {
  try {
    const response = yield call(updateMega, user)
    yield put(updateMegaSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    yield put(updateUserSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteProject1({ payload: user }) {
  try {
    const response = yield call(deleteProject1, user)

    console.log("lksdlkf",user)
    yield put(deleteProjectSuccess1(user,response))
  } catch (error) {
  
  }
}

function* onDeleteDynamicFormUser({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormUser, user)
    yield put(deleteDynamicFormSuccessUserSuccess(user,response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteClassAttanance({ payload: user }) {
  try {
    const response = yield call(deleteClassAttance, user)
    yield put(deleteClassAttananceSuccess(user,response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}



function* onDeleteLocation({ payload: user }) {
  try {
    const response = yield call(deleteLocation, user)
    yield put(deleteLocationSuccess(user,response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user)
    yield put(deleteUserSuccess(response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onAddDynamicForm({ payload: user }) {

  try {
    const response = yield call(addDynamicForm, user)

    yield put(addDynamicFormSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddDynamicForm2({ payload: user }) {

  try {
    const response = yield call(addDynamicForm2, user)

    yield put(addDynamicFormSuccess2(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddDynamicForm3({ payload: user }) {

  try {
    const response = yield call(addDynamicForm3, user)

    yield put(addDynamicForm3Success(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onAdddNewWorkshop2({ payload: user }) {

  try {
    const response = yield call(addWorkshop2, user)

    yield put(addWorkshop2Success(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddWorkshopSession({ payload: user }) {

  try {
    const response = yield call(addAppSetting, user)

    yield put(addAppSettingSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onAddNewUser({ payload: user }) {

  try {
    const response = yield call(addNewUser, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddCourse({ payload: user }) {

  try {
    const response = yield call(addCourseMega, user)

    console.log("response123213",response.value[0])
    yield put(addCourseMegaSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onAddNewMega({ payload: user }) {

  try {
    const response = yield call(addMega, user)

    yield put(addMegaSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddNews({ payload: user }) {

  try {
    const response = yield call(addNews, user)

    console.log("response123213",response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddProject({ payload: user }) {

  try {
    const response = yield call(addProject, user)

    yield put(addProjectSuccess1(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onAddSlider({ payload: user }) {

  try {
    const response = yield call(addSlider, user)

    console.log("response123213",response)
    yield put(addSliderSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddPopup({ payload: user }) {

  try {
    const response = yield call(addPopup, user)

    console.log("response123213",response)
    yield put(addPopupSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onDeleteMega({ payload: user }) {
  try {
    const response = yield call(deleteMega, user)
    console.log("user123",user)
    yield put(deleteMegaSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteNews({ payload: user }) {
  try {
    const response = yield call(deleteNews, user)
    console.log("user123",user)
    yield put(deleteNewsSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteSlider({ payload: user }) {
  try {
    const response = yield call(deleteSlider, user)
    yield put(deleteSliderSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeletePopup({ payload: user }) {
  try {
    const response = yield call(deletePopup, user)
    console.log("user123",user)
    yield put(deletePopupSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteReferance({ payload: user }) {
  try {
    const response = yield call(deleteReferances, user)
    console.log("user123",user)
    yield put(deleteReferancesSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onAddGallery({ payload: user }) {

  try {
    const response = yield call(addGallerry, user)

    yield put(addGallerySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddWorkshopSubdomain({ payload: user }) {

  try {
    const response = yield call(addNewSubdomain, user)

    yield put(addSubdomainSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onDeleteGallery({ payload: user }) {
  try {
    const response = yield call(deleteGallery, user)
    yield put(deleteGallerySuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddMultipleGallery({ payload: user }) {

  try {
    const response = yield call(addMultipleGalleryForm, user)

   //yield put(addGallerySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onDeleteCourseSchedules({ payload: user }) {
  try {
    const response = yield call(deleteCourseSchedules, user)
    yield put(deleteCourseSchedulesSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicFormExtra({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormExtra, user)
    yield put(deleteDynamicExtraFormSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicForm({ payload: user }) {
  try {
    const response = yield call(deleteDynamicForm, user)
    yield put(deleteDynamicFormSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicFormExtraExtra({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormExtraExtra, user)
    yield put(deleteDynamicExtraExtraFormSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onDeleteUserType({ payload: user }) {
  try {
    const response = yield call(deleteUserType, user)
    yield put(deleteUserTypeSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onDeleteSubDomain({ payload: user }) {
  try {
    const response = yield call(deleteSubDomain, user)
    yield put(deleteSubDomainSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddNewTeacher({ payload: user }) {

  try {
    const response = yield call(addTeacher, user)

    yield put(addNewTeacherSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onDeleteTeacher({ payload: user }) {
  try {
    const response = yield call(deleteTeacher, user)
    yield put(deleteTeacherSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteSubProject({ payload: user }) {
  try {
    const response = yield call(deleteSubProject, user)
    yield put(deleteSubProjectSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddSubProject({ payload: user }) {

  try {
    const response = yield call(addSubProject, user)

    yield put(addSubProjectSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onUpdateSubProject({ payload: user }) {
  try {
    const response = yield call(updateSubProject, user)
    yield put(updateSubProjectSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onAddAuth({ payload: user }) {

  try {
    const response = yield call(addAuth, user)

    yield put(addNewAuthSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onDeleteWorkshopSession({ payload: user }) {
  try {
    const response = yield call(deleteWorkshopSession, user)
    yield put(deleteWorkshopSessionSuccess(user,response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteWorkshop2({ payload: user }) {
  try {
    const response = yield call(deleteWokshop2, user)
    yield put(deleteWorkshop2Success(user,response,))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onDeleteLessonsTime({ payload: user }) {
  try {
    const response = yield call(deleteTeacherWorkshop, user)
    yield put(deleteLessonsTimeSuccess(user,response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddTeacherWorkshop({ payload: user }) {

  try {
    const response = yield call(addTeacherWorkshop, user)

    yield put(addTeacherWorkshopSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* contactsSaga() {
  yield takeEvery(DELETE_PROJECT, onDeleteProject1)

  yield takeEvery(GET_USERPAYMENT, onGetUserPayment)

  yield takeEvery(GETTSDASHBOARD, onGetTeacherSessionDashBoard)

  yield takeEvery(GET_USERCLASS, onGetUserClass)

  yield takeEvery(GET_USERNULL, onGetUserNull)

  yield takeEvery(UPDATE_FORMSUCCESSUSER_NULL, onUpdateFormSuccessUserNull)

  yield takeEvery(GET_USERDYNAMICFORM_NULL, onGetUserDynamicFormNull)

  yield takeEvery(GET_ALLDYNAMICFORM, onGetAllDynamicForm)

  yield takeEvery(UPDATE_FORMSUCCESSUSER, onUpdateFormSuccessUser)

  yield takeEvery(DELETE_DYNAMICFORMUSER, onDeleteDynamicFormUser)

  yield takeEvery(UPDATE_DYNAMICFORM2, onUpdateDynamicFormExtra)

  yield takeEvery(GET_USERDYNAMICFORM, onGetUserDynamicForm)

  yield takeEvery(DELETE_CLASSATTANCE, onDeleteClassAttanance)

  yield takeEvery(DELETE_LOCATION, onDeleteLocation)

  yield takeEvery(UPDATE_LOCATION, onUpdateLocation)

  yield takeEvery(ADD_LOCATION, onAddLocation)

  yield takeEvery(GET_LOCATION, onGetLocationMega)

  yield takeEvery(GET_ALLWORKSHOPMEGA, onGetAllWorkshopMega)

  yield takeEvery(GET_TEACHERSESSIONMEGA, onGetTeacherSessionMega)

  yield takeEvery(ADD_TEACHERWORKSHOP, onAddTeacherWorkshop)

  yield takeEvery(DELETE_LESSONSTIME, onDeleteLessonsTime)

  yield takeEvery(DELETE_WORKSHOP2, onDeleteWorkshop2)

  yield takeEvery(DELETE_WORKSHOPSESSION, onDeleteWorkshopSession)

  yield takeEvery(ADD_DYNAMIC_FORM3, onAddDynamicForm3)

  yield takeEvery(ADD_DYNAMIC_FORM2, onAddDynamicForm2)

  yield takeEvery(DELETE_USERTYPE, onDeleteUserType)

  yield takeEvery(UPDATE_POPUP, onUpdatePopup)

  yield takeEvery(ADD_AUTH, onAddAuth)

  yield takeEvery(UPDATE_SUBPROJECT, onUpdateSubProject)

  yield takeEvery(ADD_SUBPROJECT, onAddSubProject)

  yield takeEvery(DELETE_SUBPROJECT, onDeleteSubProject)

  yield takeEvery(DELETE_TEACHER, onDeleteTeacher)

  yield takeEvery(ADD_NEWTEACHER, onAddNewTeacher)

  yield takeEvery(DELETE_SUBDOMAIN, onDeleteSubDomain)

  yield takeEvery(DELETE_DYNAMICFORMEXTRAEXTRA, onDeleteDynamicFormExtraExtra)

  yield takeEvery(DELETE_DYNAMICFORM, onDeleteDynamicForm)

  yield takeEvery(DELETE_DYNAMICFORMEXTRA, onDeleteDynamicFormExtra)

  yield takeEvery(DELETE_COURSESCHEDULES, onDeleteCourseSchedules)

  yield takeEvery(ADD_GALLERYMULTIPLE, onAddMultipleGallery)

  yield takeEvery(DELETE_GALLERY, onDeleteGallery)

  yield takeEvery(ADD_GALLERY, onAddGallery)

  yield takeEvery(DELETE_REFERANCES, onDeleteReferance)

  yield takeEvery(DELETE_POPUP, onDeletePopup)

  yield takeEvery(DELETE_SLIDER, onDeleteSlider)

  yield takeEvery(DELETE_NEWS, onDeleteNews)

  yield takeEvery(DELETE_MEGA, onDeleteMega)


  yield takeEvery(GET_DYNAMICFORM_EXTRA_EXTRA, onGetDynamicFormExtraExtra)

  yield takeEvery(GET_DYNAMICFORM_EXTRA, onGetDynamicFormExtra)

  yield takeEvery(ADD_DYNAMIC_FORM, onAddDynamicForm)

  yield takeEvery(UPDATE_DYNAMICFORM, onUpdateDynamicForm)

  yield takeEvery(GET_CLASSES, onGetClasses)

  yield takeEvery(GET_CLASSATTENDANCE, onGetClassAttendance)

  yield takeEvery(UPDATE_LESSONS_TIME, onUpdateLessonsTime)

  yield takeEvery(ADD_LESSONS_TIME, onAddLessonsTime)

  yield takeEvery(GET_TEACHER_WORKSHOP, onGetTeacherWorkshopMega)

  yield takeEvery(GET_SUBPROJECT, onGetSubProject)

  yield takeEvery(UPDATE_REFERANCES, onUpdateReferances)

  yield takeEvery(ADD_REFERANCES, onAddReferances)

  yield takeEvery(GET_REFERANCES, onGetReferances)

  yield takeEvery(ADD_WORKSHOP2, onAdddNewWorkshop2)

  yield takeEvery(ADD_APPSETTING, onAddWorkshopSession)

  yield takeEvery(ADD_WORKSHOPSUBDOMAIN, onAddWorkshopSubdomain)

  yield takeEvery(UPDATE_GALLERY, onUpdateGalllery)

  yield takeEvery(GET_GALLERY, fetchGallery)

  yield takeEvery(UPDATE_WORKSHOP2, onUpdateWorkshop2)

  yield takeEvery(GET_WORKSHOP2, fetchWorkshop2)

  yield takeEvery(UPDATE_MEGA, onUpdateMega)

  yield takeEvery(UPDATE_PROJECT, onUpdateProject)

  yield takeEvery(UPDATE_SLIDER, onUpdateSlider)

  yield takeEvery(UPDATE_COURSESCHEDULES, onUpdateCourseSchedules)

  yield takeEvery(UPDATE_AUTH, onUpdateAuth)

  yield takeEvery(GET_AUTHORITY, fetchAuthority)

  yield takeEvery(UPDATE_CONTACT, onUpdateContact)

  yield takeEvery(GET_CONTACT, fetchContact)

  yield takeEvery(UPDATE_TEACHER, onUpdateTeacher)

  yield takeEvery(UPDATE_WORKSHOPSESSION, onUpdateWorkshopSession)

  yield takeEvery(UPDATE_SUBDOMAIN, onUpdateSubdomain)

  


  yield takeEvery(ADD_COURSE, onAddCourse)

  yield takeEvery(UPDATE_NEWS, onUpdateNews)

  yield takeEvery(ADD_MEGA, onAddNewMega)

  yield takeEvery(ADD_NEWS, onAddNews)

  yield takeEvery(ADD_PROJECT, onAddProject)

  yield takeEvery(ADD_SLIDER, onAddSlider)

  yield takeEvery(ADD_POPUP, onAddPopup)

  yield takeEvery(GET_MEGA, fetchMega)

  yield takeEvery(GET_NEWS, fetchNews)

  yield takeEvery(GET_PROJECTSNEW, fetchProjects)

  yield takeEvery(GET_SLIDER, fetchSlider)

  yield takeEvery(GET_DYNAMICFORM, fetcDynamicFormMega)

  yield takeEvery(GET_EVENT, fetcEventMega)

  yield takeEvery(GET_TeacherMega, fetcTeacherMega)

  yield takeEvery(GET_CourseSchedulesMega, fetchCourseSchedulesMega)

  yield takeEvery(GET_WorkshopSessionMega, fetchWorkshopSessionMega)

  yield takeEvery(GET_WORKSHOP, fetchWorkshop)

  yield takeEvery(GET_POPUP, fetchPopup)

  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default contactsSaga;
