import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";

import * as Yup from "yup";

import {
  updateContact as onUpdateConcat,
  getContactMega as onGetContact,
} from "store/contacts/actions";

import axios from 'axios';

const EcommerceAddProduct = () => {
  document.title = "SMS Gönder | MEGA Admin Paneli";

  const dispatch = useDispatch();


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameSurname: '',
      gsmNumber: '',
      messageText:  ''

        },
    validationSchema: Yup.object({
      nameSurname: Yup.string().required("Lütfen Adınızı Soyadınızı Giriniz"),
      gsmNumber: Yup.string().required("Lütfen Telefon Numaraınızı Giriniz")

            }),
    onSubmit: (values) => {
     
      sendMessage(values.nameSurname,values.gsmNumber,values.messageText)

        // update order
       // dispatch(onUpdateConcat(updateOrder));
        validation.resetForm();
    
    },
  });


  const sendMessage = async(name,number,message) => {
    let data = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"\r\nxmlns="https://webservice.asistiletisim.com.tr/SmsProxy">\r\n<soapenv:Header/>\r\n <soapenv:Body>\r\n <sendSms>\r\n <requestXml>\r\n <![CDATA[\r\n <SendSms>\r\n <Username>megaapi</Username> \r\n <Password>Mg/*-8520</Password>\r\n <UserCode>6817</UserCode>\r\n <AccountId>2124</AccountId>\r\n <Originator>MERAM BLD</Originator>\r\n <SendDate></SendDate>\r\n <ValidityPeriod>60</ValidityPeriod>\r\n <MessageText>${message}</MessageText>\r\n <IsCheckBlackList>0</IsCheckBlackList>\r\n <ReceiverList>\r\n <Receiver>${number}</Receiver>\r\n </ReceiverList>\r\n </SendSms>\r\n ]]>\r\n </requestXml>\r\n </sendSms>\r\n </soapenv:Body>\r\n</soapenv:Envelope>`;
    

    await axios.post(`https://getjob.stechomeyazilim.info:5101/postMessageMega/send`, data).then(async(res) => {

    console.log("res12321",res)
    })
        
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
  }



  const { users } = useSelector(state => ({
    users: state.contacts.mega,
  }));
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="SMS Gönder" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>SMS Gönder</CardTitle>

                  <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                    <Row>
                   
                        <div className="mb-3">
                          <Label htmlFor="productname">Ad Soyad</Label>
                          <Input
                            name="nameSurname"
                            type="text"
                            className="form-control"
                            placeholder="Ad Soyad Giriniz"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.nameSurname || ""}
                            invalid={
                              validation.touched.nameSurname && validation.errors.nameSurname ? true : false
                            }
                          />
                          {validation.touched.nameSurname && validation.errors.nameSurname ? (
                            <FormFeedback type="invalid">{validation.errors.nameSurname}</FormFeedback>
                          ) : null}
                         
                        </div>
                      

                        <div className="mb-3">
                          <Label htmlFor="price">Gsm Numarası</Label>
                          <Input
                            id="gsmNumber"
                            name="gsmNumber"
                            type="text"
                            className="form-control"
                            placeholder="Telefon Numarası"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.gsmNumber || ""}
                            invalid={
                              validation.touched.gsmNumber && validation.errors.gsmNumber ? true : false
                            }
                          />
                          {validation.touched.gsmNumber && validation.errors.adgsmNumberess ? (
                            <FormFeedback type="invalid">{validation.errors.gsmNumber}</FormFeedback>
                          ) : null}
                        
                        </div>

                     
                        <div className="mb-3">
                          <Label htmlFor="price">Mesaj İçeriği</Label>
                          <Input
                            name="messageText"
                            type="text"
                            className="form-control"
                            placeholder="Mesaj İçeriği"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.messageText || ""}
                            invalid={
                              validation.touched.messageText && validation.errors.messageText ? true : false
                            }
                          />
                          {validation.touched.messageText && validation.errors.messageText ? (
                            <FormFeedback type="invalid">{validation.errors.messageText}</FormFeedback>
                          ) : null}
                         
                        </div>

                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Gönder
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

        
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
