import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import Select from "react-select";
import MultiSelectOption from "./MultiSelectOption";
import {ExportToHeader} from '../../ExportToHeader'

import {ExportToExcel} from '../../ExportToExcel'


// Pages Components
import WelcomeComp from "./WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";


import {
  getChats as onGetChats,
} from "store/actions";

import {
  getClasses as onGetClasses,
  getCourseSchedulesMega as ongetCourseSchedulesMega,
  getProjectsNew as onGetProjectsNew,
  getUsers as onGetUsers,
  getTeacherSessionMega as onGetTeacherSessionMega,
  getTSDashboard as onGetTeacherSessionDashBoard,
  getWorkshopSessionMega as onGetWorkshopSessionMega,

} from "store/contacts/actions";
import axios from "axios";

const Dashboard = props => {

  const [getClassModalVisible, setClassModalVisible] = useState(false);
  const [getItemData, setItemData] = useState(null);
  const [getFilterDetail, setFilterDetail] = useState(null);

  const colourStyles = {
    menuList: styles => ({
        ...styles,
        background: 'papayawhip'
    }),
    option: (styles, {isFocused, isSelected}) => ({
        ...styles,
        background: isFocused
            ? 'white'
            : isSelected
                ? 'black'
                : undefined,
        zIndex: 1
    }),
    menu: base => ({
        ...base,
        zIndex: 100
    })
    }
  
  const dispatch = useDispatch();

  const { teacherSession } = useSelector(state => ({
    teacherSession: state.contacts.teacherSession,
  }));

  const { classes } = useSelector(state => ({
    classes: state.contacts.classes,
  }));

  const { messagesNew } = useSelector(state => ({
    messagesNew: state.chat.chats,
  }));

  const { workshopSchedules } = useSelector(state => ({
    workshopSchedules: state.contacts.workshopSession,
  }));

  const { projects } = useSelector(state => ({
    projects: state.contacts.projects,
  }));

  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));


  const reports = [
    { title: "Toplam Mesaj", iconClass: "bx-copy-alt", description: messagesNew.length },
    { title: "Toplam Projeler", iconClass: "bx-archive-in", description: projects.length },
    {
      title: "Toplam Üyeler",
      iconClass: "bx-purchase-tag-alt",
      description:  users.length,
    },
    {
      title: "Aktif Olan Atölyeler",
      iconClass: "bx-purchase-tag-alt",
      description: workshopSchedules.length,
    },
    {
      title: "Atölyedeki Öğrenci Sayısı",
      iconClass: "bx-purchase-tag-alt",
      description: classes.length,
    },
  ];

  const [_gelectedUser, _selectedUser] = useState([]);
  const [getIsClassVisible, setIsClassVisible] = useState(false);
  const [getClassCapacity, setClassCapacity] = useState(null);
  const [getClass, setClass] = useState([]);
  const [getClassUser, setClassUser] = useState([]);
  const [getUserList, setUserList] = useState([]);
  const [getSelectUserList, setSelectUserList] = useState([]);
  const [getSelectTeacherList, setSelectTeacherList] = useState([]);

  const _getClassesExtra= async (item) => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/searchClassesTeacherSession/select/${item}`)
    .then((res) => {

      if(res.data.length>0){
        setClassUser(res.data)
      }

    })
  }
  
  const handleChange= async (val,type) => {
          if(type=="teacher"){
        setSelectTeacherList(val)
      }
      if(type == "user"){
        setSelectUserList(val)
      }
    }

  const toggleClass = () => {
    if (getIsClassVisible) {
      setIsClassVisible(false);
     // setOrder(null);
    } else {
      setIsClassVisible(true);
    }
  };

  const handleOpenClass = (arg,capacity) => {
    const order = arg;
    console.log("order1cccc23",order)

    _selectedUser([])

    teacherSession.map(async(item, index) => {
      setClass(oldArray => [...oldArray,{ID: item.ID, Classes:item.Classes,label:item.Users.NameSurname,value:item.Users.ID}] )
   }) 
    setIsClassVisible(true);
    setClassCapacity(capacity);

    toggleClass();
  };
  
useEffect(() => {
  if (teacherSession && !teacherSession.length) {
    dispatch(onGetTeacherSessionMega());
  }
}, [dispatch, teacherSession]);



  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
    }
  }, [dispatch, users]);

  useEffect(() => {
    if (projects && !projects.length) {
      dispatch(onGetProjectsNew());
    }
  }, [dispatch, projects]);

  const { dashboardTs } = useSelector(state => ({
    dashboardTs: state.contacts.dashboardTs,
  }));
  
  useEffect(() => {
    if (dashboardTs && !dashboardTs.length) {
      dispatch(onGetTeacherSessionDashBoard());
    }
  }, [dispatch, projects]);
  
  useEffect(() => {
    if (workshopSchedules && !workshopSchedules.length) {
      dispatch(ongetCourseSchedulesMega());
    }
  }, [dispatch, workshopSchedules]);

  const { workshopsessionMega } = useSelector(state => ({
    workshopsessionMega: state.contacts.workshopsessionMega,
  }));
  
  useEffect(() => {
    if (workshopsessionMega && !workshopsessionMega.length) {
      dispatch(onGetWorkshopSessionMega());
    }
  }, [dispatch, projects]);
  

  useEffect(() => {
    if (classes && !classes.length) {
      dispatch(onGetClasses());
    }
  }, [dispatch, classes]);

  useEffect(() => {
    if (messagesNew && !messagesNew.length) {
      dispatch(onGetChats());
    }
  }, [dispatch, messagesNew]);

  const _setOpenModal = (item,filterDetail) => {
    setClassModalVisible(!getClassModalVisible)
    setItemData(item)
    setFilterDetail(filterDetail)
  };


  const _handleTeacher= async (val) => {

    _selectedUser(val)
     console.log("lsdcclfk",val)
     _getClassesExtra(val.ID)
     }
   
     const dataUsers =  getClassUser.map(elt=> [
      elt.Users.NameSurname,
      elt.Users.Users_TCNumber,
      elt.Users.GsmNumber
            ]);

  //meta title
  document.title = "Anasayfa | MEGA Admin Paneli";



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Yönetim")}
            breadcrumbItem={props.t("Anasayfa")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

             
              <Row>
                {/* Reports Render */}
                
                {workshopsessionMega.length > 0 ? 
                 workshopsessionMega.map((item, index) => {
                  let documlength = 0; 
let docum = item.Course_Schedules.map((elt, index1) => {
  elt.Teacher_Session.map((elt1, index2) => {
    documlength += elt1.Classes.length;
    // You can do something else with elt1 here if needed
  });
});

                  return(
                  <Col md="4" key={"_col_" + index}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                            Başvuru Dönemi: {item.Workshop_Sessions_Text}
                            </p>
                          </div>
                        </div>

                        <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                           Toplam Başvuru : {documlength}
                            </p>
                          </div>

          
                 {documlength > 0 ? 
                 <ExportToExcel apiData={item.Course_Schedules.map((elt,index1)=>  (
                          elt.Teacher_Session.map((elt1,index2)=> (
                            elt1.Classes.map((elt2,index3)=> 
                              [
                                index3 + 1,
                                elt2.Users.NameSurname,
                                elt2.Users.GsmNumber
                              ]                           
                          )
                        )
                        )))}   fileName={item.Workshop_Sessions_Text.substring(0,205)} />  : null}
          
                     


                      </CardBody>
                    </Card>
                  </Col>
                )}) : null}
              </Row>
              
              <Row>
                {/* Reports Render */}
                {dashboardTs.map((item, index) => {
                let filterDetail = item.Course_Schedules.Teacher_Session.length > 0 ? item.Course_Schedules.Teacher_Session[0].Classes.filter((x)=> x.Teacher_Session_ID == item.ID) : []
                  return(
                  <Col md="4" key={"_col_" + index}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div className="bg-danger d-flex"> 
 
                            <p className="text-white fw-medium text-center">
                            Gün {item.Sheduled_DayTitle}
                            </p>

                            <p className="text-white fw-medium text-center">
                            Başlangış {item.StartTime}
                            </p>

                            <p className="text-white fw-medium text-center">
                            Bitiş {item.FinishTime}
                            </p>
                            </div>

                            {item.Course_Schedules.Workshop_Sessions != null ?  <p className="text-muted fw-medium">
                            Başvuru Dönemi: {item.Course_Schedules.Workshop_Sessions.Workshop_Sessions_Text}
                            </p> : null}

                            {item.Course_Schedules.Workshop!= null ? <p className="text-muted fw-medium">
                            Atölye : {item.Course_Schedules.Workshop.Workshop_Text}
                            </p> : null}


                            <p className="text-muted fw-medium">
                            Başvuru Sayısı: {filterDetail.length}
                            </p>
                            <p className="text-muted fw-medium">
                            Kontenjan: {item.Course_Schedules.Number_OfQuotas}
                            </p>
                            
                            <p className="text-muted fw-medium">
                            Yaş Aralığı: {item.Course_Schedules.Age_Range}
                            </p>


                          
                          </div>
                          
                      
                        </div>

          {item.Course_Schedules.Teacher_Session.length > 0 ? item.Course_Schedules.Teacher_Session[0].Classes.length > 0 ? <div className="text-sm-end">
            
  <ExportToHeader apiData={item.Course_Schedules.Teacher_Session[0].Classes.map((elt,index)=>  [
        index + 1,
    elt.Users.NameSurname,
    elt.Users.GsmNumber,
    item.Course_Schedules.Workshop.Workshop_Text,
    item.Sheduled_DayTitle,
    item.StartTime + " " +  item.FinishTime

          ])} 
          
          fileName={item.Course_Schedules.Workshop.Workshop_Text.substring(0,50) + item.Sheduled_DayTitle + "-" + item.StartTime  + "-"  + item.FinishTime} /> 
            </div> : null : null}

            

                      </CardBody>
                    </Card>
                  </Col>
                )})}
              </Row>

            </Col>
          </Row>

        
          {/* <Row>
          <Col xl="12">
          <ActivityComp setOpenModal={_setOpenModal} teacherSession={teacherSession}/>
          </Col>
                  </Row> */}

         
        </Container>
      </div>


      <Modal isOpen={getIsClassVisible} toggle={toggleClass}>
            <ModalHeader toggle={toggleClass} tag="h4">
              {!!getIsClassVisible ? "Sınıflar" : "Sınıflar"}
            </ModalHeader>
            <ModalBody>


            <label className="form-label">
                         Toplam {getClassCapacity} Kontenjan
                          </label>


            <div className="mb-3" style={{ zIndex: "1005" }}>
                          <label className="form-label">
                           Öğretmen Seçiniz
                          </label>
                          <Select
                          placeholder={"Öğretmen Seçiniz"}
                            value={_gelectedUser}
                            isMulti={false}
                            onChange={(val) => {
                              _handleTeacher(val);
                            }}
                            options={getClass}
                            className="select2-selection"
                            styles={colourStyles}
                          />
                        </div>

                        {getClassUser.length > 0 ?
                        <ExportToExcel apiData={dataUsers} fileName={"fileName"} /> 
                        : null}


                      

      { _gelectedUser != null ? <div style={{ zIndex: "1001" }}>
        <MultiSelectOption handleChange={handleChange} fullData={getUserList} value={getSelectUserList} type={"user"}  />
        </div> : null}

        <div className="text-end">
                      <button
                      onClick={()=> _postUser()}
                        type="button"
                        className="btn btn-success save-user">
                        Ekle
                      </button>
                    </div>

           {getClassUser.length > 0 ?
          
          getClassUser.map((item, index) => {
           return(
            <div className="d-flex align-items-center" key={index}>
               <p
  key={index}
className="text-success text-center">
Öğrenci - {item.Users.NameSurname} 
</p>

<button
                      onClick={()=> _deleteClassUser(item.ID)}
                        type="button"
                        className="btn btn-danger save-user">
                        Sil
                      </button>

</div>

         )}) : 
        
          <div>
            <p
className="text-success text-center">
KAYITLI ÖĞRENCİ YOK</p>
</div> }
           
                <Row className="d-flex">
             
                  <Col>
                    <div className="text-end">
                      <button
                      onClick={()=> toggleClass()}
                        type="button"
                        className="btn btn-danger save-user">
                        Kapat
                      </button>
                    </div>
                  </Col>

                </Row>
              
              
              
            </ModalBody>
          </Modal>

      <Modal
        isOpen={getClassModalVisible}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setClassModalVisible(!getClassModalVisible);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setClassModalVisible(!getClassModalVisible);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
           

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Kayıtlı Öğrenciler</h4>
                {getItemData != null && getFilterDetail.length > 0 ? <p className="text-muted font-size-16 mb-2">
                 {getFilterDetail[0].Users.NameSurname}
                </p> : null}

             
              </div>
            </div>
          </div>
        </div>
      </Modal>
 
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
