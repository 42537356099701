import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_REFERANCES_SUCCESS,
  GET_WORKSHOP2_SUCCESS,
  GET_POPUP_SUCCESS,
  UPDATE_POPUP_SUCCESS,
  UPDATE_SUBDOMAIN_SUCCESS,
  UPDATE_SUCCESS_COURSESCHEDULES,
  UPDATE_WORKSHOPSESSION_SUCCESS,
  UPDATE_SUCCESS_TEACHER,
  UPDATE_SUCCESS_SLIDER,
  UPDATE_SUCCESS_NEWS,
  UPDATE_SUCCESS_MEGA,
  UPDATE_WORKSHOP2_SUCCESS,
  UPDATE_SUCCESS_GALLERY,

  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOPSESSION_SUCCESS,
  GET_APPLICATION_SUCCESS,
  GET_SLIDER_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_MEGA_SUCCESS,
  GET_TEACHER_SUCCESS,
  GET_DYNAMIC_SUCCESS,
  GET_CONTACT,
  GET_AUTH_SUCCESS,
  GET_AUTHUPDATE_SUCCESS,
  UPDATE_SUCCESS_PROJECT,
  GET_GALLERY_SUCCESS,
  ADD_REFERANCES_SUCCESS,
  UPDATE_REFERANCES_SUCCESS,
  GET_SUBPROJECT_SUCCESS,
  GET_TEACHERWORKSHOP_SUCCESS,
  ADD_LESSONSTIME_SUCCESS,
  UPDATE_LESSONSTIME_SUCCESS,
  GET_CLASSATTENDANCE_SUCCESS,

  GET_CLASSES_SUCCESS,
  UPDATE_DYNAMICFORM_SUCCESS,
  ADD_DYNAMICFORM_SUCCESS,
  GET_DYNAMICFORMEXTRA_SUCCESS,
  GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  ADD_MEGA_SUCCESS,
  DELETE_MEGA_SUCCESS,
  DELETE_NEWS_SUCCESS,
  DELETE_SLIDER_SUCCESS,
  DELETE_POPUP_SUCCESS,
  DELETE_REFERANCES_SUCCESS,
  ADD_GALLERY_SUCCESS,

  DELETE_GALLERY_SUCCESS,
  DELETE_COURSESCHEDULES_SUCCESS,
  DELETE_DYNAMICFORMEXTRA_SUCCESS,
  DELETE_DYNAMICFORM_SUCCESS,

  DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  DELETE_SUBDOMAIN_SUCCESS,
  ADD_SUBDOMAIN_SUCCESS,
  ADD_NEWTEACHER_SUCCESS,
  DELETE_TEACHER_SUCCESS,
  DELETE_SUBPROJECT_SUCCESS,
  UPDATE_SUBPROJECT_SUCCESS,
  ADD_SUBPROJECT_SUCCESS,
  ADD_AUTH_SUCCESS,
  DELETE_USERTYPE_SUCCESS,
  ADD_DYNAMICFORM_SUCCESS2,
  ADD_DYNAMICFORM_SUCCESS3,
  ADD_APPSETTING_SUCCESS,

  DELETE_WORKSHOPSESSION_SUCCESS,
  ADD_WORKSHOP2_SUCCESS,
  DELETE_WORKSHOP2_SUCCESS,
  ADD_COURSEMEGA_SUCCESS,
  DELETE_LESSONSTIME_SUCCESS,
  ADD_SLIDER_SUCCESS,
  ADD_TEACHERWORKSHOP_SUCCESS,
  GET_TEACHERSESSIONMEGA_SUCCESS,
  GET_LOCATION_SUCCESS,
  ADD_LOCATION_SUCCESS,
  UPDATE_LOCATION_SUCCESS,

  DELETE_LOCATION_SUCCESS,
  DELETE_CLASSATTANCE_SUCCESS,
  GET_USERDYNAMICFORM_SUCCESS,
  UPDATE_DYNAMICFORM2_SUCCESS,
  DELETE_DYNAMICFORMUSER_SUCCESS,
  UPDATE_FORMSUCCESSUSER_SUCCESS,
  GET_ALLDYNAMICFORM_SUCCESS,
  GET_USERDYNAMICFORMNULL_SUCCESS,
  UPDATE_FORMSUCCESSUSERNULL_SUCCESS,
  GET_USERNULL_SUCCESS,
  GET_USERCLASS_SUCCESS,
  GETTSDASHBOARD_SUCCESS,
  GET_USERPAYMENT,
  GET_USERPAYMENT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  ADD_PROJECT_SUCCESS,
  ADD_POPUP_SUCCESS,
  UPDATE_COURSESCHEDULES_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userProfile: {},
  error: {},
  popup: [],
  workshop: [],
  workshopSession: [],
  workshopsessionMega: [],
  slider: [],
  projects: [],
  news:[],
  mega:[],
  teacher:[],
  dynamic:[],
  contact:[],
  authlist:[],
  workshop2:[],
  gallery:[],
  referances:[],
  subProject:[],
  lessonstime:[],
  classattendance:[],
  classes:[],
  
  dynamicformextra:[],
  dynamicformextraextra:[],
  workshop:[],
  lessonstime:[],
  teacherSession:[],
  location:[],
  formList:[],
  dynamicFormUser:[],
  allDynamicFormUser:[],
  dynamicFormUserNull:[],
  nullUsers:[],
  classesUser:[],
  dashboardTs:[],
  userPayment:[]
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    
   

    case DELETE_CLASSATTANCE_SUCCESS:
      return {
        ...state,
        classattendance: state.classattendance.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

    
    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        location: state.location.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        location: state.location.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
      


    case GET_USERPAYMENT_SUCCESS:
      return {
        ...state,
        userPayment: action.payload,
      }
   

    case GET_TEACHERSESSIONMEGA_SUCCESS:
      return {
        ...state,
        teacherSession: action.payload,
      }
   
    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        location: [...state.location, action.payload],
      }
    case ADD_TEACHERWORKSHOP_SUCCESS:
      return {
        ...state,
        lessonstime: [...state.lessonstime, action.payload],
      }

    case ADD_SLIDER_SUCCESS:
      return {
        ...state,
        slider: [...state.slider, action.payload],
      }

    case DELETE_LESSONSTIME_SUCCESS:
      return {
        ...state,
        lessonstime: state.lessonstime.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }
    case ADD_COURSEMEGA_SUCCESS:
      return {
        ...state,
        workshopSession: [...state.workshopSession, action.payload],
      }

    case DELETE_WORKSHOP2_SUCCESS:
      return {
        ...state,
        workshop2: state.workshop2.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }
    case ADD_WORKSHOP2_SUCCESS:
      return {
        ...state,
        workshop2: [...state.workshop2, action.payload],
      }
    case DELETE_WORKSHOPSESSION_SUCCESS:
      return {
        ...state,
        workshopsessionMega: state.workshopsessionMega.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

    case ADD_APPSETTING_SUCCESS:
      return {
        ...state,
        workshopsessionMega: [...state.workshopsessionMega, action.payload],
      }
      case ADD_DYNAMICFORM_SUCCESS3:
      return {
        ...state,
        dynamicformextraextra: [...state.dynamicformextraextra, action.payload],
      }

    case ADD_DYNAMICFORM_SUCCESS2:
      return {
        ...state,
        dynamicformextra: [...state.dynamicformextra, action.payload],
      }

    case DELETE_USERTYPE_SUCCESS:
      return {
        ...state,
        authlist: state.authlist.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case ADD_AUTH_SUCCESS:
      return {
        ...state,
        authlist: [...state.authlist, action.payload],
      }

    case ADD_SUBPROJECT_SUCCESS:
      return {
        ...state,
        subProject: [...state.subProject, action.payload],
      }

    case UPDATE_SUBPROJECT_SUCCESS:
      return {
        ...state,
        subProject: state.subProject.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case DELETE_SUBPROJECT_SUCCESS:
      return {
        ...state,
        subProject: state.subProject.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_TEACHER_SUCCESS:
      return {
        ...state,
        teacher: state.teacher.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case ADD_NEWTEACHER_SUCCESS:
      return {
        ...state,
        teacher: [...state.teacher, action.payload],
      }

    case ADD_SUBDOMAIN_SUCCESS:
      return {
        ...state,
        workshop: [...state.workshop, action.payload],
      }

    case DELETE_SUBDOMAIN_SUCCESS:
      return {
        ...state,
        workshop: state.workshop.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }
    case DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextraextra: state.dynamicformextraextra.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: state.dynamic.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }
    
    case DELETE_DYNAMICFORMEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextra: state.dynamicformextra.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_COURSESCHEDULES_SUCCESS:
      return {
        ...state,
        workshopSession: state.workshopSession.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: state.gallery.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case ADD_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: [...state.gallery, action.payload],
      }

    case DELETE_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: state.referances.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_POPUP_SUCCESS:
      return {
        ...state,
        popup: state.popup.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

      
      
      case UPDATE_FORMSUCCESSUSER_SUCCESS:
        return {
          ...state,
          dynamicFormUser: state.dynamicFormUser.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

        
        case GETTSDASHBOARD_SUCCESS:
          return {
            ...state,
            dashboardTs: action.payload,
          }
        
        case GET_USERCLASS_SUCCESS:
          return {
            ...state,
            classesUser: action.payload,
          }
        
        case GET_ALLDYNAMICFORM_SUCCESS:
          return {
            ...state,
            allDynamicFormUser: action.payload,
          }

          
          
          case GET_USERNULL_SUCCESS:
            return {
              ...state,
              nullUsers: action.payload,
            }

          case GET_USERDYNAMICFORMNULL_SUCCESS:
            return {
              ...state,
              dynamicFormUserNull: action.payload,
            }

                  
      case UPDATE_FORMSUCCESSUSERNULL_SUCCESS:
        return {
          ...state,
          dynamicFormUserNull: state.dynamicFormUserNull.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

      case GET_USERDYNAMICFORM_SUCCESS:
        return {
          ...state,
          dynamicFormUser: action.payload,
        }

        
        
        

        case ADD_POPUP_SUCCESS:
          return {
            ...state,
            popup: [...state.popup, action.payload],
          }

    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projects: [...state.projects, action.payload],
      }


        case DELETE_PROJECT_SUCCESS:
          return {
            ...state,
            projects: state.projects.filter(
              user => user.ID.toString() !== action.payload.toString()
            ),
          }

          
      case DELETE_DYNAMICFORMUSER_SUCCESS:
        return {
          ...state,
          dynamicFormUser: state.dynamicFormUser.filter(
            user => user.ID.toString() !== action.payload.toString()
          ),
        }

        
    case DELETE_SLIDER_SUCCESS:
      return {
        ...state,
        slider: state.slider.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        news: state.news.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_MEGA_SUCCESS:
        return {
          ...state,
          mega: state.mega.filter(
            user => user.ID.toString() !== action.ID.toString()
          ),
        }
  

    case ADD_MEGA_SUCCESS:
      return {
        ...state,
        mega: [...state.mega, action.payload],
      }

    case GET_DYNAMICFORMEXTRAEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextraextra: action.payload,
      }

    case GET_DYNAMICFORMEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextra: action.payload,
      }
      
      
      case UPDATE_COURSESCHEDULES_SUCCESS:
        return {
          ...state,
          workshopSession: state.workshopSession.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

      case UPDATE_DYNAMICFORM2_SUCCESS:
        return {
          ...state,
          dynamicformextra: state.dynamicformextra.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

    case UPDATE_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: state.dynamic.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case GET_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload,
      }

      
      case GET_LOCATION_SUCCESS:
        return {
          ...state,
          location: action.payload,
        }

    case GET_CLASSATTENDANCE_SUCCESS:
      return {
        ...state,
        classattendance: action.payload,
      }

    case UPDATE_LESSONSTIME_SUCCESS:
      return {
        ...state,
        lessonstime: state.lessonstime.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
      
    case ADD_DYNAMICFORM_SUCCESS:
        return {
          ...state,
          dynamic: [...state.dynamic, action.payload],
        }

    case ADD_LESSONSTIME_SUCCESS:
      return {
        ...state,
        teacherSession: [...state.teacherSession, action.payload],
      }

      case GET_TEACHERWORKSHOP_SUCCESS:
        return {
          ...state,
          lessonstime: action.payload,
        }

        
    case GET_SUBPROJECT_SUCCESS:
      return {
        ...state,
        subProject: action.payload,
      }

    case UPDATE_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: state.referances.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case GET_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: action.payload,
      }
         
    case GET_AUTHUPDATE_SUCCESS:
      return {
        ...state,
        authlist: [],
      }

    case GET_AUTH_SUCCESS:
      return {
        ...state,
        authlist: action.payload,
      }

      
      case GET_WORKSHOP2_SUCCESS:
        return {
          ...state,
          workshop2: action.payload,
        }

    case GET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      }

    case GET_DYNAMIC_SUCCESS:
      return {
        ...state,
        dynamic: action.payload,
      }

    case GET_TEACHER_SUCCESS:
      return {
        ...state,
        teacher: action.payload,
      }
    case GET_MEGA_SUCCESS:
      return {
        ...state,
        mega: action.payload,
      }

    case GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
      }

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      }

    case GET_SLIDER_SUCCESS:
      return {
        ...state,
        slider: action.payload,
      }
    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        workshopsessionMega: action.payload,
      }
    case GET_WORKSHOPSESSION_SUCCESS:
      return {
        ...state,
        workshopSession: action.payload,
      }
    case GET_WORKSHOP_SUCCESS:
      return {
        ...state,
        workshop: action.payload,
      }

      case GET_GALLERY_SUCCESS:
        return {
          ...state,
          gallery: action.payload,
        }

        
        case UPDATE_SUCCESS_GALLERY:
          return {
            ...state,
            gallery: state.gallery.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }
      
          
      case UPDATE_SUCCESS_MEGA:
        console.log("deneme123",action.payload)
        return {
          ...state,
          mega: state.mega.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }
      
        
                
      case UPDATE_WORKSHOP2_SUCCESS:
        return {
          ...state,
          workshop2: state.workshop2.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

      case UPDATE_SUCCESS_NEWS:
        return {
          ...state,
          news: state.news.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }


      case UPDATE_SUCCESS_PROJECT:
        return {
          ...state,
          projects: state.projects.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

       case UPDATE_SUCCESS_SLIDER:
        return {
          ...state,
          slider: state.slider.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

      case UPDATE_SUBDOMAIN_SUCCESS:
        return {
          ...state,
          workshop: state.workshop.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

        
        case UPDATE_SUCCESS_TEACHER:
          return {
            ...state,
            teacher: state.teacher.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }


        case UPDATE_WORKSHOPSESSION_SUCCESS:
          return {
            ...state,
            workshopsessionMega: state.workshopsessionMega.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }

        case UPDATE_SUCCESS_COURSESCHEDULES:
          console.log("lksdnfsd",state.workshopSession)
          return {
            ...state,
            workshopSession: state.workshopSession.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }

     case UPDATE_POPUP_SUCCESS:
        return {
          ...state,
          popup:  state.popup.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }
        
    case GET_POPUP_SUCCESS:
      return {
        ...state,
        popup:  action.payload,
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    
      case ADD_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: [...state.referances, action.payload],
      }


    case ADD_USER_SUCCESS:

      return {
        ...state,
        users: [...state.users, action.payload],
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }

      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          users: state.users.map(user =>
            user.ID.toString() === action.payload.ID.toString()
              ? { user, ...action.payload }
              : user
          ),
        }
  
      case UPDATE_USER_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case DELETE_USER_SUCCESS:
        return {
          ...state,
          users: state.users.filter(
            user => user.id.toString() !== action.payload.toString()
          ),
        }
  
      case DELETE_USER_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default contacts
