import React, { useState,useEffect } from "react";
import { Link,useLocation } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormGroup,
  Alert
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import axios from 'axios';


const EcommerceAddProduct = () => {
  const location = useLocation();

  const state = location.state;
  //meta title
  document.title = "Seans Birleştir | MEGA - Admin Paneli";

  const [getCourseSchedules, setCourseSchedules] = useState([]);


  const [getWsText, setWsText] = useState(null);

  const _getCourseSchedules = async () => {
  
    console.log("lmsndfls",state._item.ID)
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCourseSchedulesMega2/select/${state._item.ID}`)
    .then((res) => {
      setCourseSchedules(res.data)
    })
  }

  const _getSearchTeacherWorkshop = async (ID) => {
    console.log("state123",state._item.ID)

     await axios.get(`https://getjob.stechomeyazilim.info:5101/searchTeacherWorkshopMega2/select/${ID}`)
    .then((res) => {
      
      console.log("sjkdbfj",res.data)
      if(res.data.length > 0){
        console.log("123sldnfcclk",res.data[0].Classes)

        res.data[0].Classes.map((item, index) => {
          let jobSeekerExpreince = {
          Teacher_Workshop_ID:item.Teacher_Workshop_ID,
          Created_DateTime:item.Teacher_Workshop_ID,
          Users_ID:item.Teacher_Workshop_ID,
          IsActive	:item.Teacher_Workshop_ID,
          Teacher_Session_ID:item.Teacher_Workshop_ID
          }
          axios.post('https://getjob.stechomeyazilim.info:5101/addApply/send', jobSeekerExpreince)
      
          console.log("klnadslf")
          //sonra silme işlemi olacak
        })

        axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteClasses/${ID}`).then(async(res) => {

        console.log("delete1",res)
      })
    
      }
      
    })


   axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteCourseSchedules/${ID}`).then(async(res) => {

    console.log("delete2",res)
  })

      axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteTeacherSession/${ID}`).then(async(res) => {

    console.log(res)
  })
 
  
}
  
  
  const _setChoiceAuth = arg => {
    console.log("sdfasdas",arg.target.value)

    //setUsersTypeID(arg.target.value)
    _getSearchTeacherWorkshop(arg.target.value)
   }


  useEffect(() => {
    console.log("lsndfkls",state._item.Workshop_Sessions.Workshop_Sessions_Text,state._item.Workshop.Workshop_Text, state._item.Course_Schedules_Day,state._item.Workshop_Sessions.StartAge,state._item.Workshop_Sessions.StartAge,state._item.Workshop_Sessions.FinishAge  )
    _getCourseSchedules()
  }, []);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Atölye Seansı" breadcrumbItem="Seans Birleştir" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Seans Birleştir</CardTitle>
                  <p className="card-title-desc mb-4">
                    Bütün Alanları Eksiksiz Doldurunuz!
                  </p>


                  <Alert color="primary">
                  Seansların dikkatlice seçilmesi gerekmektedir. Yeni seçilen alanları seçip seans güncelle dediğinizde ESKİ SEANS daki öğrenciler YENİ SEANS'a aktarılacak ve eski seans sistemden silinecektir.
                    </Alert>

                   <Label className="form-label">
                    {state._item.Workshop_Sessions.Workshop_Sessions_Text + " " + state._item.Workshop.Workshop_Text+ " " + state._item.Course_Schedules_Day+ " "  + state._item.Workshop_Sessions.StartAge+ " - " + state._item.Workshop_Sessions.FinishAge + " Yaş"}
                  </Label> 

                  <Form>
                    <Row>
       

                      <Col sm="8">
                       
                     
                      <div className="mb-3">
                      <Label className="form-label">Başvuru Dönemi Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => _setChoiceAuth(text)}
                        value={
                          getWsText
                        }>

                        {getCourseSchedules.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Workshop_Sessions.Workshop_Sessions_Text + " " + item.Workshop.Workshop_Text +  " " + item.Age_Range + " Yaş Aralığı " + item.Course_Schedules_Day + " " + item.Course_Schedules_StartTime + "-" + item.Course_Schedules_FinishTime}</option>
                        ))}
                       
                      </Input>
                    
                    </div>
                    
                        
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="button" color="primary" className="btn ">
                        Birleştir
                      </Button>
                      <Button type="button" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

        
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
