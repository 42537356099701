import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import axios from "axios";

const SidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  const [getUserType, setUserType] = React.useState("Admin");
  const [getSeenPage, setSeenPage] = React.useState([]);

  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();

   let authUser =  JSON.parse(localStorage.getItem("authUser"));
   if(authUser != null){
    setUserType(authUser.Users_Type_ID)
   }

  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  useEffect(() => {
    getSearchAuth()

  }, []);

  const getSearchAuth = async () => {
    let authUser =  JSON.parse(localStorage.getItem("authUser"));

    if(authUser.Users_Type_ID != 1){
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersAuthSearch/select/${authUser.Users_Type_ID}`)
    .then((res) => {

      setSeenPage(res.data)
      console.log("lskdfncccklds",res.data)
      //setUserAuth(res.data)
    })
  }
}
  return (
    getUserType == 3 ? 
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
    <li>
       <Link to="/dashboardTeacher" >
      <i className="bx bx-file"></i>
        <span>{props.t("Anasayfa")}</span>
      </Link>
    
      </li> 

      <li>
      <Link to="/classAttendance">
      <i className='bx bx-grid'></i>
         <span>{props.t("Yoklama Al")}</span>
      </Link>
      </li>

      <li>
      <Link to="/lessonsTime">
      <i className='bx bx-book-open'></i>
         <span>{props.t("Ders Programı")}</span>
      </Link>
      </li>
      
     {/* <li>
      <Link to="/form">
      <i className='bx bx-book-open'></i>
         <span>{props.t("Form")}</span>
      </Link>
      </li> */}


      <li>
      <Link to="/logout" >
      <i className="bx bx-exit"></i>
        <span>{props.t("Çıkış")}</span>
      </Link>
      </li>
      </ul>
      </div>
      </SimpleBar>
      </React.Fragment>
    :
    getUserType == 1 ?  <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Anasayfa")} </li>
           
            <li>
              <Link to="/" >
              <i className="bx bx-home-circle"></i>
                <span>{props.t("Anasayfa")}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Yönetim")}</li>

            <li>
              <Link to="/calendar" >
                <i className="bx bx-calendar"></i>
                <span>{props.t("Etkinlik")}</span>
              </Link>
            </li>

            <li>
              <Link to="/chat" >
                <i className="bx bx-chat"></i>
                <span>{props.t("Mesajlar")}</span>
              </Link>
            </li>
           {/*  <li>
              <Link to="/apps-filemanager" >
                <i className="bx bx-file"></i>
                <span>{props.t("Dosyalar")}</span>
              </Link>
            </li>*/}


            <li>
              <Link to="/usersList" className="has-arrow">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Üyeler")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/usersList">{props.t("Üyeler")}</Link>
                </li>
                <li>
                  <Link to="/authorityList">{props.t("Yetkiler")}</Link>
                </li>
              
               
               
              </ul>
            </li>

            <li>
              <Link to="/popupList" >
                <i className="bx bx-chat"></i>
                <span>{props.t("Popup")}</span>
              </Link>
            </li>

            <li>
              <Link to="/formList" >
              <i className="bx bx-grid-alt"></i>
                <span>{props.t("Form")}</span>
              </Link>
            </li>

            <li>
              <Link to="/formListNull" >
                <i className="bx bx-grid-alt"></i>
                <span>{props.t("Boş Liste")}</span>
              </Link>
            </li>

            <li>
              <Link to="/formListNullUser" >
                <i className="bx bx-grid-alt"></i>
                <span>{props.t("BOŞ ÜYE")}</span>
              </Link>
            </li>

            <li>
              <Link to="/formListPayment" >
              <i className='bx bx-credit-card-alt' ></i>
              <span>{props.t("ÖDEME LİSTESİ")}</span>
              </Link>
            </li>

            <li>
              <Link to="/formListClass" >
              <i className='bx bx-user' ></i>
                <span>{props.t("SINIF LİSTESİ")}</span>
              </Link>
            </li>

            <li>
              <Link to="/allDynamicForm" >
              <i className='bx bxs-credit-card'></i>
                <span>{props.t("Banka Çıktı")}</span>
              </Link>
            </li>

            <li>
              <Link to="/wokshop" >
                <i className="bx bx-grid-alt"></i>
                <span>{props.t("Atölye")}</span>
              </Link>
            </li>

            <li>
              <Link to="/applicationSetting" >
                <i className="bx bx-calendar-check"></i>
                <span>{props.t("Başvuru Ayarları")}</span>
              </Link>
            </li>
     

            <li>
              <Link to="/workShopSession" >
                <i className="bx bx-calendar"></i>
                <span>{props.t("Atölye Seansları")}</span>
              </Link>
            </li>

          

            <li>
              <Link to="/teacherList" >
                <i className="bx bx-user-check"></i>
                <span>{props.t("Öğretmen Listesi")}</span>
              </Link>
            </li>

            <li>
              <Link to="/dinamikForm" >
                <i className="bx bx-book-content"></i>
                <span>{props.t("Dinamik Formlar")}</span>
              </Link>
            </li>

            <li>
              <Link to="/locationList" >
                <i className="bx bx-book-content"></i>
                <span>{props.t("Tesis")}</span>
              </Link>
            </li>

            <li>
              <Link to="/slider" >
                <i className="bx bx-slider"></i>
                <span>{props.t("Slider")}</span>
              </Link>
            </li>

            

            <li>
              <Link to="/gallery" >
                <i className="bx bx-photo-album"></i>
                <span>{props.t("Galeri")}</span>
              </Link>
            </li>

            <li>
              <Link to="/projects" >
                <i className="bx bx-archive-in"></i>
                <span>{props.t("Projeler")}</span>
              </Link>
            </li>

          
            <li>
              <Link to="/news" >
                <i className="bx bx-news"></i>
                <span>{props.t("Haberler")}</span>
              </Link>
            </li>
                
            <li>
              <Link to="/mega" >
                <i className="bx bx-bulb"></i>
                <span>{props.t("Mega")}</span>
              </Link>
            </li>

            <li>
              <Link to="/referances" >
                <i className="bx bx-copyright"></i>
                <span>{props.t("Referanslar")}</span>
              </Link>
            </li>
          

            <li>
              <Link to="/contact" >
                <i className="bx bx-customize"></i>
                <span>{props.t("İletişim")}</span>
              </Link>
            </li>

            <li>
              <Link to="/sms" >
                <i className="bx bx-customize"></i>
                <span>{props.t("SMS")}</span>
              </Link>
            </li>
        
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment> : 
     <React.Fragment>
     <SimpleBar className="h-100" ref={ref}>
       <div id="sidebar-menu">
         <ul className="metismenu list-unstyled" id="side-menu">
           <li className="menu-title">{props.t("Anasayfa")} </li>
          
           <li>
             <Link to="/" >
             <i className="bx bx-home-circle"></i>
               <span>{props.t("Anasayfa")}</span>
             </Link>
           </li>

           <li className="menu-title">{props.t("Yönetim")}</li>

           {getSeenPage.map((item, key) => (
                <li key={key}>
                <Link to={item.Authority.Authority_Link} >
                  <i className="bx bx-calendar"></i>
                  <span>{item.Authority.Authority_Text}</span>
                </Link>
              </li>
              ))}
         </ul>
       </div>
     </SimpleBar>
   </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
